// Customizable Area Start
export const truckIcon = require('../assets/trcukImage.png');
export const trcukdetailsIcon = require('../assets/trcukdetailsIcon.png');
export const tickIcon = require('../assets/Icon.png');
export const crossIcon = require('../assets/image_.png');
export const person = require('../assets/person.svg');

export const mastercard = require('../assets/mastercardLogo.svg');
export const visa = require('../assets/visaLogo.svg');
// Customizable Area End
