import { BlockComponent } from '../../../../framework/src/BlockComponent';
import { IBlock } from '../../../../framework/src/IBlock';
import { Message } from '../../../../framework/src/Message';
import MessageEnum, { getName } from '../../../../framework/src/Messages/MessageEnum';
import { runEngine } from '../../../../framework/src/RunEngine';
import { getStorageData } from '../../../../framework/src/Utilities';

export type Root = SavedCardsResponse[];

export interface Card {
  brand: string;
  exp_month: number;
  exp_year: number;
  last4: string;
}

export interface SavedCardsResponse {
  payment_method_id: string;
  card: Card;
}

export interface CardItem extends SavedCardsResponse {
  isPrimary: boolean;
}

export interface Props {
  navigation?: any;
  id?: string;
  onClickClose?: () => void;
  onClickApply?: () => void;
  loadId?: number;
  oldDesign?: boolean;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  isLoggedType: string | null;
  userToken: string | null;
  strapiApiKey: string | null;
  isLoadingCards: boolean;
  isErrorCardsList: boolean;
  cardsList: SavedCardsResponse[];
  selectedCardId: string;
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ApplyModalController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiCallIds = {
    stripeApiKey: '',
    getSavedCards: '',
    postCard: '',
    postDefaultCard: '',
  };

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      isLoggedType: null,
      userToken: null,
      strapiApiKey: null,

      isLoadingCards: true,
      isErrorCardsList: false,
      cardsList: [],

      selectedCardId: '',
    };
  }

  async componentDidMount() {
    super.componentDidMount();
    await this.isUserLogged();
    this.loadSavedCards();
  }

  sendApiRequest = ({
    callIdKey,
    endpoint,
    method,
    body,
    header,
  }: {
    callIdKey: keyof ApplyModalController['apiCallIds'];
    endpoint: string;
    method: 'GET' | 'POST' | 'DELETE' | 'PATCH' | 'PUT';
    header?: Record<string, string>;
    body?: any;
  }) => {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiCallIds[callIdKey] = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);

    if (body) {
      requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
    }

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  isUserLogged = async () => {
    const isLoggedType = await getStorageData('LoggedInUserType');
    const userToken = await getStorageData('RefreshToken');

    !userToken && this.redirectTo('EmailAccountLoginBlock');

    this.setState({ userToken, isLoggedType });
  };

  loadSavedCards = () => {
    this.sendApiRequest({
      callIdKey: 'getSavedCards',
      endpoint: 'bx_block_stripe_integration/payments/saved_cards',
      method: 'GET',
      header: {
        'Content-Type': 'application/json',
        token: this.state.userToken!,
      },
    });
  };

  redirectTo = (path: string) => {
    const messages = new Message(getName(MessageEnum.NavigationMessage));
    messages.addData(getName(MessageEnum.NavigationTargetMessage), path);
    messages.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(messages);
  };

  openCardForm = () => {
    this.sendApiRequest({
      callIdKey: 'stripeApiKey',
      endpoint: '/bx_block_stripe_integration/payments/get_public_key',
      method: 'GET',
      header: {
        'Content-Type': 'application/json',
        token: this.state.userToken!,
      },
    });
  };

  saveCard = (token: any) => {
    this.sendApiRequest({
      callIdKey: 'postCard',
      endpoint: `/bx_block_stripe_integration/payments/save_card?card_token=${token.id}`,
      method: 'POST',
      header: {
        'Content-Type': 'application/json',
        token: this.state.userToken!,
      },
    });
  };

  setDefaultCard = (cardId: string) => {
    this.sendApiRequest({
      callIdKey: 'postDefaultCard',
      endpoint: '/bx_block_stripe_integration/payments/set_default_card',
      method: 'POST',
      header: {
        'Content-Type': 'application/json',
        token: this.state.userToken!,
      },
      body: {
        charge: {
          payment_method_id: cardId,
        },
      },
    });
  };

  handleCardClicked = (id: string) => {
    this.setState({ selectedCardId: id });
    console.log('hanldelcikc :', id);
  };

  handleStripeApiKeyRes = (responseJson: any) => {
    if (responseJson.errors)
      return this.showAlert(
        'Error',
        responseJson.errors[0]?.message || 'Failed to load Stripe key'
      );

    const publicKey = responseJson['public_key'];
    console.log(publicKey);
    if (publicKey) {
      this.setState({ strapiApiKey: publicKey });
    }
  };

  handleSavedCardsRes = (responseJson: any) => {
    if (responseJson.errors) {
      this.showAlert('Error', responseJson.errors[0]?.message || 'Failed to load Stripe key');
      this.setState({ isErrorCardsList: true });
      return;
    }
    if (responseJson.length === 0) this.openCardForm();

    const defaultCard = responseJson.find((card: any) => card.default);
    let defaultList: any[] = [];
    let defaultCardId = '';
    if (defaultCard !== undefined) {
      defaultList = [defaultCard];
      defaultCardId = defaultCard.payment_method_id;
    }

    this.setState({
      cardsList: defaultList,
      isLoadingCards: false,
      isErrorCardsList: false,
      selectedCardId: defaultCardId,
    });
  };

  handlePostCardRes = (responseJson: any) => {
    if (responseJson.errors)
      return this.showAlert('Error', responseJson.errors[0]?.message || 'Failed to create card');

    if (this.state.cardsList.length === 0 && responseJson.payment_method.payment_method_id)
      this.setDefaultCard(responseJson.payment_method.payment_method_id);

    this.loadSavedCards();
  };

  handlePostDefaultCardRes = (responseJson: any) => {
    if (responseJson.errors)
      return this.showAlert('Error', responseJson.errors[0]?.message || 'Failed to create card');

    this.loadSavedCards();
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      const handlers: { [key: string]: (responseJson: any) => void } = {
        [this.apiCallIds.stripeApiKey]: this.handleStripeApiKeyRes,
        [this.apiCallIds.getSavedCards]: this.handleSavedCardsRes,
        [this.apiCallIds.postCard]: this.handlePostCardRes,
        [this.apiCallIds.postDefaultCard]: this.handlePostDefaultCardRes,
      };

      const handler = handlers[apiRequestCallId];
      if (handler) {
        handler(responseJson);
      }
    }
  }
}
