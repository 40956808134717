import React from 'react';

// Customizable Area Start
import { logo } from './assets';
import LayoutSettingsController, { configJSON, Props } from './LayoutSettingsController';
import { styled } from '@mui/material/styles';

// Customizable Area End

export default class LayoutSettings extends LayoutSettingsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <CustomLayout data-test-id="main-layout">
        <header>
          <img src={logo} alt={configJSON.logoAlt} className="logo" />
          <div className="actions">
            <label className="searchbar">
              <div className="search-icon">
                <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M15.186 14.473h.79l4.24 4.26c.41.41.41 1.08 0 1.49-.41.41-1.08.41-1.49 0l-4.25-4.25v-.79l-.27-.28a6.5 6.5 0 0 1-5.34 1.48c-2.78-.47-5-2.79-5.34-5.59a6.505 6.505 0 0 1 7.27-7.27c2.8.34 5.12 2.56 5.59 5.34a6.5 6.5 0 0 1-1.48 5.34l.28.27Zm-9.71-4.5c0 2.49 2.01 4.5 4.5 4.5s4.5-2.01 4.5-4.5-2.01-4.5-4.5-4.5-4.5 2.01-4.5 4.5Z"
                    fill="currentColor"
                  />
                </svg>
              </div>
              <input type="text" placeholder="Search" />
            </label>
            <button className="notifications">
              <svg width="24" height="25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M19.29 17.54 18 16.25v-5c0-3.07-1.64-5.64-4.5-6.32v-.68c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.63 5.61 6 8.17 6 11.25v5l-1.29 1.29c-.63.63-.19 1.71.7 1.71h13.17c.9 0 1.34-1.08.71-1.71ZM8 17.25v-6c0-2.48 1.51-4.5 4-4.5s4 2.02 4 4.5v6H8Zm6 3a2 2 0 1 1-4 0h4Z"
                  fill="currentColor"
                />
              </svg>
            </button>
            <button className="menu">
              <img src={configJSON.avatarUrl} alt={configJSON.avatarUrlAlt} />
              <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="m15.875 9-3.88 3.88L8.115 9a.996.996 0 1 0-1.41 1.41l4.59 4.59c.39.39 1.02.39 1.41 0l4.59-4.59a.996.996 0 0 0 0-1.41c-.39-.38-1.03-.39-1.42 0Z"
                  fill="currentColor"
                />
              </svg>
            </button>
          </div>
        </header>
        <div className="body">
          <nav>
            <button className="icon" data-selected={this.props.view === 'dashboard'}>
              <svg width="24" height="25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M6 19.5h3v-6h6v6h3v-9L12 6l-6 4.5v9Zm0 2c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 4 19.5v-9c0-.317.071-.617.213-.9.141-.283.337-.517.587-.7l6-4.5a2.11 2.11 0 0 1 .575-.3c.2-.067.408-.1.625-.1.217 0 .425.033.625.1s.392.167.575.3l6 4.5a1.99 1.99 0 0 1 .8 1.6v9c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0 1 18 21.5h-5v-6h-2v6H6Z"
                  fill="currentColor"
                />
              </svg>
              <span>{configJSON.navDashboard}</span>
            </button>
            <button className="icon" data-selected={this.props.view === 'loads'}>
              <svg width="24" height="25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M6 20.5c-.55 0-1.02-.196-1.412-.587A1.926 1.926 0 0 1 4 18.5c0-.55.196-1.02.588-1.413A1.926 1.926 0 0 1 6 16.5c.55 0 1.02.196 1.412.587.392.392.588.863.588 1.413s-.196 1.02-.588 1.413A1.926 1.926 0 0 1 6 20.5Zm6 0c-.55 0-1.02-.196-1.412-.587A1.926 1.926 0 0 1 10 18.5c0-.55.196-1.02.588-1.413A1.926 1.926 0 0 1 12 16.5c.55 0 1.02.196 1.412.587.392.392.588.863.588 1.413s-.196 1.02-.588 1.413A1.926 1.926 0 0 1 12 20.5Zm6 0c-.55 0-1.02-.196-1.413-.587A1.926 1.926 0 0 1 16 18.5c0-.55.196-1.02.587-1.413A1.926 1.926 0 0 1 18 16.5c.55 0 1.02.196 1.413.587.391.392.587.863.587 1.413s-.196 1.02-.587 1.413A1.926 1.926 0 0 1 18 20.5Zm-12-6c-.55 0-1.02-.196-1.412-.588A1.926 1.926 0 0 1 4 12.5c0-.55.196-1.02.588-1.412A1.926 1.926 0 0 1 6 10.5c.55 0 1.02.196 1.412.588.392.391.588.862.588 1.412 0 .55-.196 1.02-.588 1.412A1.926 1.926 0 0 1 6 14.5Zm6 0c-.55 0-1.02-.196-1.412-.588A1.926 1.926 0 0 1 10 12.5c0-.55.196-1.02.588-1.412A1.926 1.926 0 0 1 12 10.5c.55 0 1.02.196 1.412.588.392.391.588.862.588 1.412 0 .55-.196 1.02-.588 1.412A1.926 1.926 0 0 1 12 14.5Zm6 0c-.55 0-1.02-.196-1.413-.588A1.926 1.926 0 0 1 16 12.5c0-.55.196-1.02.587-1.412A1.926 1.926 0 0 1 18 10.5c.55 0 1.02.196 1.413.588.391.391.587.862.587 1.412 0 .55-.196 1.02-.587 1.412A1.926 1.926 0 0 1 18 14.5Zm-12-6c-.55 0-1.02-.196-1.412-.588A1.926 1.926 0 0 1 4 6.5c0-.55.196-1.02.588-1.412A1.926 1.926 0 0 1 6 4.5c.55 0 1.02.196 1.412.588C7.804 5.479 8 5.95 8 6.5c0 .55-.196 1.02-.588 1.412A1.926 1.926 0 0 1 6 8.5Zm6 0c-.55 0-1.02-.196-1.412-.588A1.926 1.926 0 0 1 10 6.5c0-.55.196-1.02.588-1.412A1.926 1.926 0 0 1 12 4.5c.55 0 1.02.196 1.412.588.392.391.588.862.588 1.412 0 .55-.196 1.02-.588 1.412A1.926 1.926 0 0 1 12 8.5Zm6 0c-.55 0-1.02-.196-1.413-.588A1.926 1.926 0 0 1 16 6.5c0-.55.196-1.02.587-1.412A1.926 1.926 0 0 1 18 4.5c.55 0 1.02.196 1.413.588.391.391.587.862.587 1.412 0 .55-.196 1.02-.587 1.412A1.926 1.926 0 0 1 18 8.5Z"
                  fill="currentColor"
                />
              </svg>
              <span>{configJSON.navLoads}</span>
            </button>
            <button className="icon" data-selected={this.props.view === 'messages'}>
              <svg width="24" height="25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M4.8 3.5h14.4c.99 0 1.8.81 1.8 1.8v10.8c0 .99-.81 1.8-1.8 1.8H6.6L3 21.5V5.3c0-.99.81-1.8 1.8-1.8Zm1.8 12.6h11.7c.495 0 .9-.405.9-.9v-9c0-.495-.405-.9-.9-.9H5.7c-.495 0-.9.405-.9.9v11.7l1.8-1.8Z"
                  fill="currentColor"
                />
              </svg>
              <span>{configJSON.navMessages}</span>
            </button>
            <button className="icon" data-selected={this.props.view === 'notifications'}>
              <svg width="24" height="25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M19.29 17.54 18 16.25v-5c0-3.07-1.64-5.64-4.5-6.32v-.68c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.63 5.61 6 8.17 6 11.25v5l-1.29 1.29c-.63.63-.19 1.71.7 1.71h13.17c.9 0 1.34-1.08.71-1.71ZM8 17.25v-6c0-2.48 1.51-4.5 4-4.5s4 2.02 4 4.5v6H8Zm6 3a2 2 0 1 1-4 0h4Z"
                  fill="currentColor"
                />
              </svg>
              <span>{configJSON.navNotifications}</span>
            </button>
          </nav>
          <main>{this.props.children}</main>
        </div>
      </CustomLayout>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

// add css styles
const CustomLayout = styled('div')`
  & * {
    box-sizing: border-box;
  }
  button {
    cursor: pointer;
  }

  font-family: 'Inter';
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;

  header {
    padding: 8px 28px;
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;

    .actions {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 16px;

      button {
        border: none;
        background-color: transparent;
        font-family: inherit;
      }

      .searchbar {
        display: flex;
        padding: 8px 14px;
        gap: 8px;
        background-color: #f6f6f6;
        border: 1px solid #d6d3d1;
        border-radius: 98px;
        .search-icon {
          color: #a8a29e;
        }
        input {
          border: none;
          font-size: 16px;
          background-color: transparent;
          width: 100%;
          &:focus {
            outline: none;
          }
          &::placeholder {
            font-size: 16px;
          }
        }

        &:has(input:focus) {
          outline: 2px solid #615f5e;
        }
      }

      .notifications {
        background-color: #f6f6f6;
        color: #a8a29e;
        padding: 8px;
        border-radius: 100px;
      }

      .menu {
        display: flex;
        gap: 4px;
        align-items: center;
        color: #a8a29e;
        img {
          width: 42px;
          height: 42px;
          object-fit: cover;
          border-radius: 100px;
        }
      }
    }
  }

  .body {
    flex: 1;
    display: grid;
    grid-template-columns: auto 1fr;

    nav {
      display: grid;
      align-content: center;
      justify-items: center;
      gap: 40px;
      padding: 20px;
      font-size: 12px;
      color: #78716c;

      .icon {
        cursor: pointer;
        border: none;
        background-color: transparent;
        padding: 0;
        color: currentColor;
        font-family: inherit;
        font-size: inherit;
        display: grid;
        justify-items: center;
        gap: 4px;

        &[data-selected='true'] {
          color: #b57730;
          span {
            font-weight: 700;
          }
        }
      }
    }

    main {
      background-color: #f6f6f6;
      border-radius: 16px 0 0 0;
      padding: 40px;
    }
  }

  @media (width < 768px) {
    header {
      padding: 8px 16px;
    }
    .body {
      nav {
        padding: 16px;
        font-size: 8px;
      }
    }
  }
  @media (width < 640px) {
    header {
      padding: 8px 16px 16px 16px;
      .logo {
        width: 42px;
        height: 42px;
      }
      .actions .searchbar input {
        font-size: 14px;
      }
    }
    .body {
      border-top: 1px solid #e1e1e1;
      grid-template-columns: 1fr;
      grid-template-rows: auto 1fr;
      nav {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        padding: 16px;
        font-size: 10px;
      }
      main {
        border-radius: 0;
        padding: 16px;
      }
    }
  }
`;

// Customizable Area End
