//@ts-nocheck
import React from 'react';
// Customizable Area Start
import {
  IconButton,
  Box,
  Typography,
  Divider,
  TextField,
  Button,
} from '@mui/material';
import HeaderNavigation from '../../navigationmenu/src/HeaderNavigation.web';
import {
  CalendarToday,
  CheckCircleRounded,
  Close,
} from '@mui/icons-material';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import ReactSignatureCanvas from 'react-signature-canvas';
import NavigationMenu from '../../navigationmenu/src/NavigationMenu.web';
import { createTheme, ThemeProvider } from '@mui/material/styles';

// Customizable Area End

import CarrierAgreementController, { Props, configJSON } from './CarrierAgreementController.web';

export default class CarrierAgreement extends CarrierAgreementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const submitBtnStyle = this.state.isDisable
      ? styles.btnSubmitDisabled
      : styles.btnSubmitEnabled;
    return (
      <Box>
        <HeaderNavigation navigation={this.props.navigation} id={''} />
        <Box sx={{ display: 'flex', flex: 1 }}>
          <NavigationMenu navigation={this.props.navigation} id={''} />
          <Box sx={{ backgroundColor: '#F6F6F6', padding: '32px' }}>
            <ThemeProvider theme={theme}>
              <Typography sx={styles.headerFont}>{configJSON.Agreement}</Typography>
              <Box
                sx={{
                  backgroundColor: 'white',
                  borderRadius: '8px',
                  padding: '16px',
                }}
              >
                <Box
                  sx={{
                    border: '1px solid #E7E5E4',
                    borderRadius: '8px',
                    padding: '24px',
                    width: '80%',
                  }}
                >
                  <Typography sx={styles.title}>{configJSON.title}</Typography>
                  <Divider sx={{ color: '#DEE5ED', height: '2px' }} />
                  <Box sx={styles.contentContainer}>
                    <Typography sx={{ ...styles.ruleText, lineHeight: 1.8 }}>
                      {configJSON.agreementIntro}
                      <Box
                        sx={{
                          display: 'inline-flex',
                          alignItems: 'center',
                          gap: 2,
                          marginInline: 2,
                        }}
                      >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={['DatePicker']} sx={{ width: '100%' }}>
                            <DatePicker
                              format="YYYY-MM-D"
                              data-test-id="selectDate"
                              onChange={this.handleDateChange}
                              value={this.state.selectedDate}
                              slots={{
                                openPickerIcon: CalendarToday,
                              }}
                              maxDate={dayjs()}
                              slotProps={{
                                openPickerIcon: { fontSize: '20px' },
                                openPickerButton: {
                                  sx: {
                                    color: '#0F172A',
                                  },
                                },
                                textField: {
                                  placeholder: 'Select date',
                                  hiddenLabel: true,
                                  sx: {
                                    width: '100%',
                                    '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline':
                                      {
                                        border: '1px solid #CBD5E1',
                                      },
                                    '& .MuiInputBase-input::placeholder': {
                                      color: '#475569',
                                      opacity: 1,
                                    },
                                  },
                                },
                              }}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                        <Typography sx={styles.inputErrorMessage}>
                          {this.state.inputErrors.selectedDate}
                        </Typography>
                        <Typography>{configJSON.dateLabel}</Typography>
                        <TextField
                          data-test-id="printedName"
                          style={styles.inputContainer}
                          placeholder={'Enter Name'}
                          name="printedName"
                          value={this.state.printedName}
                          onChange={this.handleChange}
                        />
                        <Typography sx={styles.inputErrorMessage}>
                          {this.state.inputErrors.printedName}
                        </Typography>
                      </Box>
                      , hereafter referred to as <b>{configJSON.Carrier}</b> and{' '}
                      <b>"{configJSON.appName}"</b>. Whereas, the
                      <b>"{configJSON.appName}"</b> is a transportation dispatching app creating a
                      safe, user-friendly load board for <b>{configJSON.Shippers}</b> and{' '}
                      <b>{configJSON.Carrier}s</b> to secure "cargo" for said{' '}
                      <b>"{configJSON.Carrier}s"</b>. The <b>{configJSON.Carrier}</b> referring to a
                      contract motor carrier subject to the jurisdictions of the ICC now and
                      thereforth in considerations of the promises and covenants hereinafter
                      contained it is mutually agreed by and between parties hereto as follows:
                    </Typography>

                    <Box sx={styles.contentContainer}>
                      <Typography
                        sx={{
                          ...styles.contentHeader,
                          textDecoration: 'underline',
                        }}
                      >
                        {configJSON.obligationHeader}
                      </Typography>
                      <ol style={styles.ruleText}>
                        <li>{configJSON.obligation1}</li>
                        <li>{configJSON.obligation2}</li>
                      </ol>
                    </Box>
                    <Box sx={styles.contentContainer}>
                      <Typography
                        sx={{
                          ...styles.contentHeader,
                          textDecoration: 'underline',
                        }}
                      >
                        {configJSON.carrierObligationHeader}
                      </Typography>
                      <ol style={styles.ruleText}>
                        <li>{configJSON.carrierObligation1}</li>
                      </ol>
                    </Box>
                    <Box sx={styles.contentContainer}>
                      <Typography sx={{ ...styles.contentHeader, marginBottom: '8px' }}>
                        {configJSON.carrierSignatureLabel}
                      </Typography>
                      <Box style={styles.boxContainer}>
                        <ReactSignatureCanvas
                          penColor="black"
                          ref={this.state.sigCanvasRef}
                          canvasProps={{
                            className: 'sigCanvas',
                            style: { ...styles.canvasStyles },
                          }}
                          onBegin={this.handleOnBegin}
                          onEnd={this.handleSignaturePad}
                        />
                      </Box>
                      {this.state.showSignatureError && (
                        <Typography style={styles.inputErrorMessage}>
                          {configJSON.emptyMessage}
                        </Typography>
                      )}
                    </Box>
                    <Button
                      data-test-id="submitBtnWrapper"
                      onClick={() => {
                        this.handleSubmitForm();
                      }}
                      size={'small'}
                      title={'submit'}
                      disabled={this.state.isDisable}
                      style={submitBtnStyle}
                    >
                      SUBMIT
                    </Button>
                    {this.state.successMsg && (
                      <div style={styles.modalBackdrop}>
                        <div style={styles.modalContent}>
                          <div style={styles.trueIconBox}>
                            <IconButton disabled>
                              <CheckCircleRounded sx={{ color: '#34D399' }} />
                            </IconButton>
                            <p style={styles.successMsg}>{this.state.successMsg}</p>
                          </div>
                        </div>
                      </div>
                    )}
                    {this.state.errorMsg && (
                      <div style={styles.modalBackdrop}>
                        <div style={styles.modalContent}>
                          <div style={styles.trueIconBox}>
                            <div style={styles.crossImage}>
                              <Close style={{ color: '#FFF', fontSize: '24px' }} />
                            </div>
                            <p style={styles.successMsg}>{this.state.errorMsg}</p>
                          </div>
                        </div>
                      </div>
                    )}
                  </Box>
                </Box>
              </Box>
            </ThemeProvider>
          </Box>
        </Box>
      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: '#fff',
      contrastText: '#fff',
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: '20px 0px',
    },
  },
});

const styles = {
  contentContainer: {
    margin: '24px 0',
  },
  title: {
    fontFamily: 'Inter',
    fontWeight: 400,
    fontSize: '24px',
    lineHeight: '32px',
    color: '#0F243E',
    margin: '24px 0',
  },
  headerFont: {
    marginBottom: '20px',
    fontWeight: 700,
    fontSize: '30px',
    fontFamily: 'Inter',
    color: '#215089',
  },
  contentHeader: {
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '24px',
  },
  ruleText: {
    fontSize: 16,
    fontWeight: '400',
    fontFamily: 'Inter',
    color: '#475569',
    lineHeight: '25px',
  },
  Container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  boxContainer: {
    height: '30vh',
    width: '100%',
    overflow: 'hidden',
    alignSelf: 'center',
    borderRadius: '16px',
  },
  inputErrorMessage: {
    fontSize: 12,
    color: 'red',
    fontFamily: 'inter',
    marginTop: '7px',
  },
  btnSubmitDisabled: {
    padding: '8px 24px',
    backgroundColor: '#FDF1E2',
    color: '#D97706',
  },
  btnSubmitEnabled: {
    padding: '8px 24px',
    backgroundColor: '#F59E0B',
    border: '1px solid #D97706',
    color: 'fff',
    fontWeight: 700,
  },
  canvasStyles: {
    display: 'block',
    width: '100%',
    height: '100%',
    backgroundColor: '#E2E8F0',
    border: '1px solid #CBD5E1',
    borderRadius: 8,
    marginTop: '10px',
  },
  inputContainer: {
    paddingHorizontal: '8px',
    marginTop: '6px',
    paddingVertical: '10px',
    height: '56px',
    border: '1px solid #CBD5E1',
    borderRadius: 12,
    borderStyle: 'solid',
    width: '100%',
    fontSize: 16,
    fontFamily: 'Inter',
    fontWeight: '400',
  },
  trueIconBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  crossImage: {
    backgroundColor: '#F87171',
    borderRadius: '50%',
    padding: '3px',
    margin: '10px',
    height: '25px',
    width: '25px',
  },
  successMsg: {
    fontSize: '20px',
    fontWeight: 400,
    fontFamily: 'Inter',
    color: '#0F172A',
  },
  modalContent: {
    backgroundColor: '#fff',
    borderRadius: '8px 8px 8px 8px',
    width: 'max-content',
    padding: '0px 20px',
    marginBottom: '100px',
  },
  modalBackdrop: {
    position: 'fixed' as 'fixed',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
};

// Customizable Area End
