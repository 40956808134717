import { styled } from '@mui/material/styles';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  ElementsConsumer,
} from '@stripe/react-stripe-js';
import { loadStripe, Stripe, StripeElements } from '@stripe/stripe-js';
import React from 'react';

interface Props {
  stripeApiKey?: string;
  onSaveCard?: (paymentMethod: any) => void;
  oldDesign?: boolean;
}

interface CustomStripeFormState {
  cardError: string | null;
  expiryError: string | null;
  cvcError: string | null;
  submitError: string | null;
  isProcessing: boolean;
  saveAsPrimary: boolean;
  isCardComplete: boolean;
  isExpiryComplete: boolean;
  isCvcComplete: boolean;
}

class CustomStripeForm extends React.Component<Props, CustomStripeFormState> {
  static defaultProps = {
    onSaveCard: () => {}, // Default empty function
  };

  constructor(props: Props) {
    super(props); // Pass props to the parent constructor
    // Other initialization code here
    this.state = {
      cardError: null,
      expiryError: null,
      cvcError: null,
      submitError: null,
      isProcessing: false,
      saveAsPrimary: false,
      isCardComplete: false,
      isExpiryComplete: false,
      isCvcComplete: false,
    };
  }

  handleSubmit = async (
    event: React.FormEvent,
    stripe: Stripe | null,
    elements: StripeElements | null
  ) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    this.setState({ isProcessing: true, submitError: null });

    try {
      const { error: stripeError, token } = await stripe.createToken(
        elements.getElement(CardNumberElement)!
      );

      if (stripeError) throw stripeError;

      this.setState({ isProcessing: false });

      this.props.onSaveCard?.(token);
    } catch (error) {
      this.setState({
        submitError: error instanceof Error ? error.message : 'An error occurred',
        isProcessing: false,
      });
    }
  };

  handleCardChange = (event: any) => {
    this.setState({
      isCardComplete: event.complete,
      cardError: event.error?.message || null,
    });
  };

  handleExpiryChange = (event: any) => {
    this.setState({
      isExpiryComplete: event.complete,
      expiryError: event.error?.message || null,
    });
  };

  handleCvcChange = (event: any) => {
    this.setState({
      isCvcComplete: event.complete,
      cvcError: event.error?.message || null,
    });
  };

  handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ saveAsPrimary: event.target.checked });
  };

  get isFormValid(): boolean {
    return this.state.isCardComplete && this.state.isExpiryComplete && this.state.isCvcComplete;
  }

  renderFormElements = (stripe: Stripe | null, elements: StripeElements | null) => {
    const formOptions = {
      style: {
        base: {
          fontSize: '16px',
          color: '#424770',
          '::placeholder': { color: '#475569', fontSize: '16px' },
        },
        invalid: { color: '#9e2146' },
      },
    };

    return (
      <StripeForm
        onSubmit={(e: React.FormEvent) => this.handleSubmit(e, stripe, elements)}
        className="stripe-form"
      >
        <div className="form-group">
          <label>Card number</label>
          <CardNumberElement
            className="stripe-element"
            options={formOptions}
            onChange={this.handleCardChange}
          />
          {this.state.cardError && (
            <div className="field-error" data-test-id="card-error">
              {this.state.cardError}
            </div>
          )}
        </div>

        <div className="form-group">
          <label>Expiration date</label>
          <CardExpiryElement
            className="stripe-element"
            options={formOptions}
            onChange={this.handleExpiryChange}
          />
          {this.state.expiryError && (
            <div className="field-error" data-test-id="expiry-error">
              {this.state.expiryError}
            </div>
          )}
        </div>

        <div className="form-group">
          <label>Security code</label>
          <CardCvcElement
            className="stripe-element"
            options={formOptions}
            onChange={this.handleCvcChange}
          />
          {this.state.cvcError && (
            <div className="field-error" data-test-id="cvc-error">
              {this.state.cvcError}
            </div>
          )}
        </div>

        <div className="form-group checkbox">
          <label>
            <input
              type="checkbox"
              checked={this.state.saveAsPrimary}
              onChange={this.handleCheckboxChange}
            />
            Save as primary payment method
          </label>
        </div>

        {this.state.submitError && <div className="submit-error">{this.state.submitError}</div>}

        <button
          type="submit"
          disabled={!this.isFormValid || this.state.isProcessing}
          className="submit-button"
          data-old={this.props.oldDesign}
        >
          {this.state.isProcessing ? 'Processing...' : 'SAVE CARD'}
        </button>
      </StripeForm>
    );
  };

  render() {
    return (
      <Elements stripe={loadStripe(this.props.stripeApiKey!)} data-test-id="stripe-form">
        <ElementsConsumer>
          {({ stripe, elements }) => this.renderFormElements(stripe, elements)}
        </ElementsConsumer>
      </Elements>
    );
  }
}

export default CustomStripeForm;

const StripeForm = styled('form')`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 500px;

  .stripe-element {
    border: 1px solid #cbd5e1;
    padding: 10px 8px;
    border-radius: 8px;

    &.StripeElement--focus {
      border-color: #2563eb;
      box-shadow: 0 0 0 1px #2563eb;
    }

    &.StripeElement--invalid {
      border-color: #9e2146;
    }
  }

  .form-group {
    display: grid;
    gap: 8px;
    label {
      color: #0f172a;
      font-size: 14px;
      font-weight: 700;
    }
  }

  .field-error {
    color: #9e2146;
    font-size: 12px;
    margin-top: 4px;
  }

  .submit-error {
    color: #9e2146;
    text-align: center;
    padding: 8px;
    border-radius: 4px;
    background-color: #fee2e2;
  }

  .form-group.checkbox {
    display: none;
    label {
      display: flex;
      gap: 8px;
      align-items: center;
    }
    input[type='checkbox'] {
      accent-color: rgb(33, 80, 137);
      width: 16px;
      height: 16px;
    }
  }

  .submit-button {
    border: 1px solid #d97706;
    background-color: #f59e0b;
    color: #fffbeb;
    padding: 16px;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 700;
    transition: opacity 0.2s;
    :disabled {
      background-color: #e2dacc;
      border-color: #e2dacc;
      opacity: 0.7;
      cursor: not-allowed;
    }
    :not(:disabled):hover {
      opacity: 0.9;
    }
    &[data-old='true'] {
      background: linear-gradient(99.09deg, #915f26 2.64%, #f19f40 100%);
      border: none;
      :disabled {
        background: #fdf1e2;
        color: #b57730;
      }
    }
  }
`;
