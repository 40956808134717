import { IBlock } from '../../../framework/src/IBlock';
import { Message } from '../../../framework/src/Message';
import { BlockComponent } from '../../../framework/src/BlockComponent';
import MessageEnum, { getName } from '../../../framework/src/Messages/MessageEnum';
import { runEngine } from '../../../framework/src/RunEngine';

// Customizable Area Start
import React from 'react';
import { getStorageData } from '../../../framework/src/Utilities';

export interface CityList {
  name: string;
  code: string;
}
export interface ApiRes {
  message?: string;
  error?: [string];
}
export interface IInputError {
  selectedDate: string;
  printedName: string;
  signatureFile: string;
}
// Customizable Area End

export const configJSON = require('./config');

export interface Props {
  // Customizable Area Start
  navigation: object;
  id: string;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  token: string;
  inputErrors: IInputError;
  selectedDate: Date | null;
  printedName: string;
  isDisable: boolean;
  sigCanvasRef: any;
  signImg: string;
  signaturePadFile: File | null;
  successMsg: string;
  errorMsg: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string | number;
  // Customizable Area End
}

export default class InvoiceBillingController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  sendApiDataId: string = '';
  getApiDataId: string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: '',
      successMsg: '',
      errorMsg: '',
      inputErrors: {} as IInputError,
      selectedDate: null,
      printedName: '',
      isDisable: true,
      signaturePadFile: null,
      signImg: '',
      sigCanvasRef: React.createRef(),
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      let webResponseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (webApiRequestCallId === this.sendApiDataId) {
        this.handlePostAPIResponse(webResponseJson);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    const token = await getStorageData('LoginToken');
    this.setState({ token: token }, () => {
      this.getPoaData();
    });
  }
  handlePostAPIResponse = (response: ApiRes) => {
    if (response.message === 'Carrier agreement created successfully') {
      this.state.sigCanvasRef.current.clear();
      this.setState(
        {
          successMsg: 'Form submitted successfully!',
          printedName: '',
          selectedDate: null,
          signaturePadFile: null,
        },
        () =>
          setTimeout(() => {
            this.isFormValid();
            this.setState({ successMsg: '' });
            this.redirectTo('TruckerDashboard');
          }, 2500)
      );
    }
    if (response.error) {
      this.setState({ errorMsg: response.error[0] }, () =>
        setTimeout(() => {
          this.setState({ errorMsg: '' });
        }, 3000)
      );
    }
  };

  redirectTo = (path: string) => {
    const messages = new Message(getName(MessageEnum.NavigationMessage));
    messages.addData(getName(MessageEnum.NavigationTargetMessage), path);
    messages.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(messages);
  };

  handleNameValidation = (updateError: IInputError) => {
    if (!this.state.selectedDate) {
      updateError.selectedDate = 'Please select Date';
    }
  };

  handleValidate = () => {
    let updateError = { ...this.state.inputErrors } as IInputError;
    const { printedName } = this.state;

    this.handleNameValidation(updateError);

    if (!printedName) {
      updateError.printedName = 'Printed name is required.';
    }

    if (this.state.sigCanvasRef.current.isEmpty()) {
      updateError.signatureFile = 'Signature is required';
    }
    this.setState({ inputErrors: updateError });

    return Object.values(updateError).every((value) => value === '');
  };

  base64ToBlob = (base64: string) => {
    const byteString = atob(base64.split(',')[1]);
    const mimeString = base64.split(',')[0].split(':')[1].split(';')[0];

    const abcd = new ArrayBuffer(byteString.length);
    const iaaa = new Uint8Array(abcd);
    for (let index = 0; index < byteString.length; index++) {
      iaaa[index] = byteString.charCodeAt(index);
    }

    return new Blob([abcd], { type: mimeString });
  };
  handleOnBegin = () => {
    this.setState((prevState) => ({
      inputErrors: { ...prevState.inputErrors, signatureFile: '' },
    }));
  };
  handleSignaturePad = () => {
    const base64String = this.state.sigCanvasRef.current.getTrimmedCanvas().toDataURL('image/png');
    const blob = this.base64ToBlob(base64String);
    const file = new File([blob], 'signature.png', { type: 'image/png' });
    this.setState(
      (prevState) =>
        ({
          ...prevState,
          signaturePadFile: file,
          inputErrors: { ...prevState.inputErrors, [`signatureFile`]: '' },
        } as Pick<S, keyof S>),
      () => this.isFormValid()
    );
  };
  handleSubmitForm = async () => {
    this.handleValidate();
    const { token, printedName, selectedDate, signaturePadFile } = this.state;

    const formData = new FormData();
    formData.append('[power_attorney]printed_name', printedName);
    selectedDate && formData.append('[power_attorney]date', selectedDate.toISOString());
    signaturePadFile && formData.append('[power_attorney]signature', signaturePadFile);

    this.sendApiDataId = await this.apiCall({
      method: configJSON.examplePOSTAPiMethod,
      endPoint: configJSON.postAttornyPath,
      token: token,
      body: formData,
    });
  };
  apiCall = async (data: any) => {
    const { method, endPoint, body, token } = data;
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    const header = {
      token: token,
    };
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
    body && requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), body);
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  getPoaData = async () => {
    this.getApiDataId = await this.apiCall({
      method: configJSON.exampleAPiMethod,
      endPoint: configJSON.postAttornyPath,
      token: this.state.token,
    });
  };

  isFormValid = () => {
    const { printedName, selectedDate, signaturePadFile } = this.state;

    const isValid = selectedDate !== null && printedName !== '' && signaturePadFile !== null;

    this.setState({ isDisable: !isValid });
  };

  handleDateChange = (date: Date | null) => {
    const formattedDate = date && new Date(date);
    this.setState(
      (prevState) => ({
        selectedDate: formattedDate,
        inputErrors: { ...prevState.inputErrors, [`selectedDate`]: '' },
      }),
      () => {
        this.isFormValid();
      }
    );
  };
  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    this.setState(
      (prevState) =>
        ({
          ...prevState,
          [name]: value,
          inputErrors: { ...prevState.inputErrors, [`${name}`]: '' },
        } as Pick<S, keyof S>),
      () => this.isFormValid()
    );
  };

  // Customizable Area End
}
