import { IBlock } from '../../../framework/src/IBlock';
import { Message } from '../../../framework/src/Message';
import { BlockComponent } from '../../../framework/src/BlockComponent';
import MessageEnum, { getName } from '../../../framework/src/Messages/MessageEnum';
import { runEngine } from '../../../framework/src/RunEngine';
import { getStorageData } from '../../../framework/src/Utilities';

// Customizable Area Start

// Customizable Area End

export const configJSON = require('./config');

export interface Card {
  brand: string;
  exp_month: number;
  exp_year: number;
  last4: string;
}
export interface SavedCardsResponse {
  payment_method_id: string;
  default: boolean;
  card: Card;
}

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  isFormOpen: boolean;
  isLoggedType: string | null;
  userToken: string | null;
  strapiApiKey: string | null;
  isLoadingCards: boolean;
  isErrorCardsList: boolean;
  cardsList: SavedCardsResponse[];
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class PaymentController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiCallIds = {
    getStripeKey: '',
    getSavedCards: '',
    postCard: '',
    postRemoveCard: '',
    postSetPrimary: '',
  };
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      isFormOpen: false,

      isLoggedType: null,
      userToken: null,
      strapiApiKey: null,

      isLoadingCards: true,
      isErrorCardsList: false,
      cardsList: [],
    };
  }

  async componentDidMount() {
    super.componentDidMount();
    await this.isUserLogged();
    this.loadSavedCards();
  }

  sendApiRequest = ({
    callIdKey,
    endpoint,
    method,
    body,
    header,
  }: {
    callIdKey: keyof PaymentController['apiCallIds'];
    endpoint: string;
    method: 'GET' | 'POST' | 'DELETE' | 'PATCH' | 'PUT';
    header?: Record<string, string>;
    body?: any;
  }) => {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiCallIds[callIdKey] = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);

    if (body) {
      requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
    }

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      const handlers: { [key: string]: (responseJson: any) => void } = {
        [this.apiCallIds.getStripeKey]: this.responseGetStripeKey,
        [this.apiCallIds.getSavedCards]: this.responseGetSavedCards,
        [this.apiCallIds.postCard]: this.responsePostCard,
        [this.apiCallIds.postRemoveCard]: this.responsePostRemoveCard,
        [this.apiCallIds.postSetPrimary]: this.responsePostSetPrimary,
      };

      const handler = handlers[apiRequestCallId];
      if (handler) {
        handler(responseJson);
      }
    }
  }

  isUserLogged = async () => {
    const isLoggedType = await getStorageData('LoggedInUserType');
    const userToken = await getStorageData('RefreshToken');

    !userToken && this.navigateTo('EmailAccountLoginBlock');

    this.setState({ userToken, isLoggedType });
  };

  navigateTo = (path: string) => {
    const messages = new Message(getName(MessageEnum.NavigationMessage));
    messages.addData(getName(MessageEnum.NavigationTargetMessage), path);
    messages.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(messages);
  };

  loadSavedCards = () => {
    this.sendApiRequest({
      callIdKey: 'getSavedCards',
      endpoint: 'bx_block_stripe_integration/payments/saved_cards',
      method: 'GET',
      header: {
        'Content-Type': 'application/json',
        token: this.state.userToken!,
      },
    });
  };
  responseGetSavedCards = (response: any) => {
    if (response?.error) {
      this.showAlert('Error', response.error.message || 'Failed to load cards');
      this.setState({ isErrorCardsList: true, isLoadingCards: false });
      return;
    }
    console.log(response);
    response.sort((a: any, b: any) => b.default - a.default);
    this.setState({
      cardsList: response,
      isLoadingCards: false,
      isErrorCardsList: false,
    });
  };

  handleOpenCardForm = () => {
    this.sendApiRequest({
      callIdKey: 'getStripeKey',
      endpoint: '/bx_block_stripe_integration/payments/get_public_key',
      method: 'GET',
      header: {
        'Content-Type': 'application/json',
        token: this.state.userToken!,
      },
    });
  };
  responseGetStripeKey = (response: any) => {
    if (response?.errors) {
      this.showAlert('Error', response.errors[0]?.message || 'Failed to load Stripe key');
      return;
    }

    const publicKey = response['public_key'];
    if (publicKey) {
      this.setState({ strapiApiKey: publicKey, isFormOpen: true });
    }
  };

  handleCloseCardForm = () => {
    this.setState({ isFormOpen: false });
  };

  handleSaveCard = (token: any) => {
    this.sendApiRequest({
      callIdKey: 'postCard',
      endpoint: `/bx_block_stripe_integration/payments/save_card?card_token=${token.id}`,
      method: 'POST',
      header: {
        'Content-Type': 'application/json',
        token: this.state.userToken!,
      },
    });
  };
  responsePostCard = (response: any) => {
    if (response?.errors) {
      this.showAlert('Error', response.errors[0]?.message || 'Failed to save card');
      return;
    }
    console.log(response);
    this.handleCloseCardForm();
    this.loadSavedCards();
  };

  handleDeleteCard = (cardId: string) => {
    this.sendApiRequest({
      callIdKey: 'postRemoveCard',
      endpoint: 'bx_block_stripe_integration/payments/remove_card',
      method: 'POST',
      header: {
        'Content-Type': 'application/json',
        token: this.state.userToken!,
      },
      body: {
        charge: {
          payment_method_id: cardId,
        },
      },
    });
  };
  responsePostRemoveCard = (response: any) => {
    if (response?.errors) {
      this.showAlert('Error', response.errors[0]?.message || 'Failed to delete card');
      return;
    }
    this.setState({ isLoadingCards: true });
    this.loadSavedCards();
  };

  handleSetPrimary = (cardId: string) => {
    this.sendApiRequest({
      callIdKey: 'postSetPrimary',
      endpoint: '/bx_block_stripe_integration/payments/set_default_card',
      method: 'POST',
      header: {
        'Content-Type': 'application/json',
        token: this.state.userToken!,
      },
      body: {
        charge: {
          payment_method_id: cardId,
        },
      },
    });
  };
  responsePostSetPrimary = (response: any) => {
    if (response.errors)
      return this.showAlert('Error', response.errors[0]?.message || 'Failed to set primary card');
    this.setState({ isLoadingCards: true });
    this.loadSavedCards();
  };
}
