Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.apiContentType = "application/json"
exports.apiFormDataContentType = "multipart/form-data"
exports.getApiMethodType = "GET"
exports.postApiMethodType = "POST"
exports.putApiMethodType = "PUT"

exports.loginApiEndPoint = "bx_block_login/logins";
exports.createTemplateApiEndpoint = "bx_block_multilevelapproval/multi_level_approvals"
exports.editTemplateApiEndpoint = "bx_block_multilevelapproval/multi_level_approvals"
exports.fetchTemplatesApiEndpoint = "bx_block_multilevelapproval/multi_level_approvals"
exports.fetchTemplatesDetailsApiEndpoint = "bx_block_multilevelapproval/multi_level_approvals"
exports.changeTemplateStatusApiEndpoint = "bx_block_multilevelapproval/multi_level_approvals/approve_or_reject_with_comment"
exports.getPerticularUsersChatEndPoint = "bx_block_chat/chats/";
exports.getCurrentLocationEndPoint = "bx_block_load/loads/";
exports.postSendMessageApiEndPoint = "bx_block_chat/messages"
exports.loginScreenTitle = "Login Screen";
exports.emailTextInputPlaceholder = "Enter your email address";
exports.passwordTextInputPlaceholder = "Enter your password";
exports.btnLoginTitle = "Login";
exports.errorPasswordText = "Please enter your password"

exports.dashboardTitle = "Dashboard";
exports.somethingWentWrong = "Something went wrong"

exports.addTempleteScreenTitle = "Add Template";
exports.editTempleteScreenTitle = "Edit Template";
exports.submit = "Submit"
exports.labelTemplateName="My Loads";
exports.placeholderTemplateName="Enter Your Template Name";
exports.labelDescription="Description";
exports.placeholderDescription="Description";
exports.errorTextTemplateName="Template Name is Required";
exports.errorTextDescription = "Description is Required";

exports.templateDetailsTitle = "Templates Details"
exports.labelConfirmation = "Please provide your confirmation"
exports.labelTemplateID = "Template ID"
exports.labelStatus = "Status"
exports.labelComment = "Comment"
exports.btnApplied = "Applied"
exports.btnApprove = "Approved"
exports.btnReject = "Rejected"
exports.patchApiMethodType = "PATCH"
exports.deleteApiMethodType = "DELETE"

exports.userRoleList = [
  {name:"level-0", value:"level-0"},
  {name:"level-1", value:"level-1"},
  {name:"level-2", value:"level-2"},
]
exports.statusList = [
  {name:"Pending", value:"pending"},
  {name:"Partial Approved", value:"partially_approved"},
  {name:"Approved", value:"approved"},
  {name:"Rejected", value:"rejected"}
]
exports.myLoadsText = "My Loads"
exports.myLoadsPath = "/bx_block_load/my_loads"
exports.applicationsPath = 'bx_block_load/applications'
exports.getStatusesPath = '/bx_block_load/loads/statuses'
exports.getDeletePath = '/bx_block_load/loads'
exports.active = "Active"
exports.inProgress = "In-Progress"
exports.completed = "Completed"
exports.viewDetailText = "View Details"
exports.idNo = "ID No";
exports.from = "From";
exports.to = "To";
exports.weight = "Weight";
exports.trukerApplied = "No.Of Truckers Applied";
exports.truckDriver = "Truck Driver"
exports.origin = "Origin";
exports.currentLocation = "Use my current location";
exports.destination = "Destination";
exports.loadWeight = "Load Weight";
exports.startDate = "Start Date";
exports.endDate = "End Date";
exports.truckType = "Truck Type";
exports.amount = "Amount";
exports.comodity = "Commodity";
exports.totalMiles = "Total Miles"
exports.originAddress = "Origin Address";
exports.destinationAddress = "Destination Address";
exports.loadDetails = "Load Details";
exports.loarem = "Lorem Ipsum is simply dummy text of the printing and typesetting industry."
exports.trucTypeList = ["VAN", "REEFER", "FLATBED", "STEPDECK", "HOTSHOT", "BOX TRUCK", "SPRINTER", "RGN/LOWBOY"]
// Customizable Area End
