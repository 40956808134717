import React from 'react';

export const IconCheck: React.FC = () => {
  return (
    <svg width="18" height="18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.6 11.93 3.975 9.305a.738.738 0 0 0-1.05 0 .738.738 0 0 0 0 1.05l3.142 3.142a.747.747 0 0 0 1.058 0l7.95-7.942a.738.738 0 0 0 0-1.05.738.738 0 0 0-1.05 0L6.6 11.93Z"
        fill="currentColor"
      />
    </svg>
  );
};
