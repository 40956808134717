import React from "react";
import {
  Box,
  Button,
  Typography,
  Dialog,
  IconButton,
  Tabs,
  Tab,
  Divider,
  Stack,
  Card, CardContent,
  Select,MenuItem
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

// Customizable Area Start
import Loader from "../../../components/src/Loader.web";
import FormControl from '@mui/material/FormControl';
import { styled } from '@mui/material/styles';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import {IMessageList} from "./MultilevelApprovalController.web"
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import HeaderNavigation from "../../navigationmenu/src/HeaderNavigation.web";
import PhoneIcon from '@mui/icons-material/Phone';
const configJSON = require("./config");
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import { trcukdetailsIcon, truckIcon,button1,button2,sendIcon ,person, tickIcon ,crossIcon} from "./assets";
import { ChatContainer, 
  MessageList, 
  MessageInput, 
  ConversationHeader, 
  Avatar, 
  Message, 
 } from '@chatscope/chat-ui-kit-react';

interface LoadResponse {
  id: number;
  type: string;
  attributes: {
    total_miles: string;
    load_broker_phone_number: string;
    load_weight: string;
    origin_address: {
      state: string;
      city: string;
      country: string;
      zip_code: string;
      address: string;
    };
    destination_address: {
      state: string;
      city: string;
      country: string;
      address: string;
      zip_code: string;
    };
    status: string;
    price: string;
    start_date: string;
    commodity: string;
    end_date: string;
    load_broker_id:number
    load_details: string;
    }
    errors:{
      message:string
    }
}


const MainContainerBox:any = styled(Box)(({ theme })=> ({
  display: "flex",
  flexDirection: "column",
  height: "730px",  
  "*":{
    fontFamily:"inter"
  },
  ".cs-status--available .cs-status__bullet":{
     background:"#80EA4E"
  },
  ".cs-conversation__name":{
    fontSize:18,
    color:"#1E293B",
    fontWeight:700,
    marginBottom:10,
  },
  ".cs-conversation__info-content":{
    color:"#78716C",
    fontSize:16,
    lineHeight:2
  },
  
  ".cs-main-container > .cs-sidebar.cs-sidebar--left":{
    maxWidth:"547px",
    width:"50%",
    border:0
  },
  ".cs-conversation__last-activity-time":{
    alignItems:"flex-start"
  },
  ".cs-conversation__content":{
    flexGrow:0,
    width:"50%"
  },
  ".cs-conversation-header":{
    background:"#215089",
    borderRadius:25,
    padding:"30px",
  },
  ".cs-message-input__tools:last-child":{
    transform:"rotate(60deg)",
    ".svg-inline--fa, .fa-paper-plane":{
      color:"black"
    }
  },
  ".cs-conversation-header__actions":{
    color:"#ffffff",
    ".css-i4bv87-MuiSvgIcon-root":{
      fontSize:"2.5rem"
    }
  },
  ".cs-conversation-header__content":{
    justifyContent:"space-around"
  },
  ".cs-conversation-header__avatar, .cs-avatar.cs-avatar--md":{
    height:78,
    width:78
  },
  ".cs-conversation-header__user-name":{
    background:"#215089",
    color:"#FFFFFF",
    fontSize:24,
    fontWeight:700
  },
  ".cs-conversation-header__info":{
    background:"#215089",
    color:"#FFFFFF",
    fontSize:16,
    fontWeight:400
  },
  ".cs-main-container":{
    border:0,
    height:"auto!important"
  },
  ".cs-chat-container":{
    border:"1px solid #DEE5ED",
    borderRadius:24,
    height:"730px",
    width: 'fit-content',
    overflowY:'auto'
    
  },
  ".cs-message--incoming .cs-message__content": {
    backgroundColor: "#E9EEF3",
    padding:"30px 20px",
    fontSize:18,
    marginTop:20
  },
  ".cs-message--outgoing .cs-message__content":{
    backgroundColor: "#BAC9DA",
    padding:"30px 20px",
    fontSize:18,
    marginTop:20
  },
  ".cs-message-input":{
    borderTop:0,
    boxShadow:"4px 4px 0px 0 #00000033",
    borderRadius:36,
    margin:20,
    marginBottom:30 ,
  },
  ".cs-message-input__content-editor-wrapper,.cs-message-input__content-editor":{
    backgroundColor:"#fff"
  },
  ".cs-button--attachment, .cs-button--send": {
    color: "#A8A29E",
    alignSelf:"center"
  },
  ".cs-search":{
    backgroundColor:"#fff",
    borderRadius:4,
    border:"1px solid #CBD5E1",
    maxWidth:344,
  },
  ".cs-search__input":{
    backgroundColor:"#fff",
    "& ::placeholder":{
      color:"#94A3B8"
    }
  },
  ".cs-search__search-icon":{
    color:"#94A3B8"
  },

  ".custom-send-button": {
    background: "none",
    border: "none",
    position: "relative",
    left: "220px",
    bottom:'60px',
    cursor: "pointer",
  },
  ".cs-back-button": {
    fontSize: "1rem", 
  },
})) ;


// Customizable Area End

import MultilevelApprovalController, {
  Props,
} from "./MultilevelApprovalController.web";
import moment from "moment";

export default class MultilevelApproval extends MultilevelApprovalController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  chatScreen = ()=>{
    const { particularChat } = this.state
    const { messages } = particularChat
    const {fileUploaded} = this.state;
    const onlineStatus = particularChat.other_user_is_online ? "available" : "invisible";
    return(        
      <MainContainerBox data-data-id="chat-main-container" >
      <ChatContainer style={{width: '100%'}}>
                <ConversationHeader>
                  <ConversationHeader.Back onClick={this.backButn}  className="cs-back-button"  />
                  {particularChat.user_profile_image ? 
                  <Avatar
                    size="fluid"
                    style={{backgroundColor:"lightGray"}}
                    src={particularChat.user_profile_image}
                    status={particularChat.other_user_is_online ? "available" : "invisible"}
                      /> : (
                        <Avatar
                          size="fluid"
                          status={onlineStatus}
                          children={<>
                            <Typography>{(!!particularChat.first_name ? particularChat.first_name : 'A').charAt(0)}</Typography>
                            <div className={`cs-status cs-status--fluid cs-status--${onlineStatus}`}>
                              <div className="cs-status__bullet"></div>
                            </div>
                          </>}
                          style={webStyles.nameAvatar}/>
                      )}
                  <ConversationHeader.Content
                  info={particularChat.other_user_is_online ? "Active Now" : ""}
                  userName={`${!!particularChat.first_name ? particularChat.first_name : ''} ${!!particularChat.last_name ? particularChat.last_name: ''}`}
                  style={{textTransform:"uppercase"}}
                  />
                  <ConversationHeader.Actions>
                  </ConversationHeader.Actions>
                </ConversationHeader>
                <MessageList style={{minHeight:"200px"}}>
      {messages?.map((mList: IMessageList) => (
         <Box sx={{
                        display:"flex", 
                        alignItems:"center", 
                        flexDirection:mList.attributes.account_id === +this.loginId ? "row-reverse": "row",
                        padding:"0 30px",
                      }}>
        <Message
          key={mList.id}
          model={{
            direction: mList.attributes.account_id === +this.loginId ? "outgoing" : "incoming",
            position: 'single',
            sender: 'Zoe',
            message: mList.attributes.message,
            sentTime: mList.attributes.created_at
          }}

        ><Message.CustomContent >
            {mList.attributes.attachments ? (
              mList.attributes.attachments?.map((attach) => (
                <Box sx={{display:"flex", flexDirection:"column"}}>
                  <img src={attach.url} alt="file-uploaded" style={webStyles.imageStyle} />
                  <span>{mList.attributes.message}</span>
                </Box>
              ))) : <span>{mList.attributes.message}</span>}
          </Message.CustomContent>
        </Message>
        <Typography sx={{
            fontWeight:400,
            fontSize:16,
          }}>{moment(mList.attributes.created_at).format('hh:mm A')}</Typography>
        </Box>
      ))}
    </MessageList>

    <MessageInput
      value={this.state.inputMessage}
      placeholder="Type a message"
      onChange={(innerHtml, textContent, innerText) => this.handleOnChange(innerHtml, textContent, innerText)}
      data-test-id="message_input"
      onSend={this.onSendMessage}
      onAttachClick={this.handleOnAttachClick}
      sendDisabled={false}
      autoFocus={true}        
    />
      </ChatContainer>              
      </MainContainerBox>)    
  }

  renderTruckDetails = () => {
     const selectedCard :any = this.state.cards.find(card => card.id === this.state.selectedCardId)     
     const selectedOption = this.statuses.find(option => option.value === selectedCard?.attributes?.status);
    return (
        <Box sx={[webStyles.truckDetailsBox, {overflowY: 'scroll'}]}>
            <Box sx={webStyles.truckIconBox}>
                <Box sx={webStyles.trcukPathBox}>
                    <Box sx={webStyles.truckDetails}>
                        <Typography sx={webStyles.fontStyle_7}>From</Typography>
                        <Typography sx={webStyles.fontStyle_8}>{selectedCard?.attributes?.origin_address?.city}</Typography>
                    </Box>
                    <Box sx={webStyles.truckDetails}>
                        <Typography sx={webStyles.fontStyle_7}>To</Typography>
                        <Typography sx={webStyles.fontStyle_8}>{selectedCard?.attributes?.destination_address?.city}</Typography>
                    </Box>
                </Box>

                <Box sx={webStyles.trcukPathBox}>
                    <Box sx={webStyles.truckDetails}>
                        <Typography sx={webStyles.fontStyle_7}>{configJSON.loadWeight}</Typography>
                        <Typography sx={webStyles.fontStyle_8}>{this.formatLoadWeight(selectedCard?.attributes?.load_weight)}</Typography>
                    </Box>
                    <Box sx={webStyles.truckDetails}>
                        <Typography sx={webStyles.fontStyle_7}>{configJSON.amount}</Typography>
                        <Typography sx={webStyles.fontStyle_8}>${parseFloat(selectedCard?.attributes?.price).toFixed(0)}</Typography>
                    </Box>
                </Box>
                <Box display={'flex'} justifyContent={'flex-end'}>
                    <img src={trcukdetailsIcon} />
                </Box>
            </Box>
            <Box style={{ ...webStyles.coverBox, flexDirection: 'column' }}>
            <Box display={'flex'} flexDirection={'column'} gap={'1rem'} p={{xs:0, sm:'0px', md:'30px'}}>
                <Box style={styles.displayFlexBox}>
                    <Typography sx={webStyles.fontStyle_9}>Full Name</Typography>
                    <Typography sx={{ ...webStyles.fontStyle_88,fontSize: '18px !important'}}>{selectedCard?.attributes?.full_name || 'NA'}</Typography>
                </Box>
                <Divider sx={webStyles.dividerStyle} />
                <Box style={styles.displayFlexBox}>
                    <Typography sx={webStyles.fontStyle_9}>Email</Typography>
                    <Typography sx={{ ...webStyles.fontStyle_88, fontSize: '18px !important',wordWrap: 'break-all',overflowWrap:'break-word' }}>{selectedCard?.attributes?.email || 'NA'}</Typography>
                </Box>
                <Divider sx={webStyles.dividerStyle} />
                <Box style={styles.displayFlexBox}>
                    <Typography sx={webStyles.fontStyle_9}>Company Name</Typography>
                    <Typography sx={{ ...webStyles.fontStyle_88, fontSize: '18px !important' }}>{selectedCard?.attributes?.company_name || 'NA'}</Typography>
                </Box>
                <Divider sx={webStyles.dividerStyle} />
                <Box style={styles.displayFlexBox}>
                    <Typography sx={webStyles.fontStyle_9}>{configJSON.startDate}</Typography>
                    <Typography sx={{ ...webStyles.fontStyle_88, fontSize: '18px !important' }}>{this.formateDate(selectedCard?.attributes?.start_date)}</Typography>
                </Box>
                <Divider sx={webStyles.dividerStyle} />

                <Box style={styles.displayFlexBox}>
                    <Typography sx={webStyles.fontStyle_9}>{configJSON.endDate}</Typography>
                    <Typography sx={{ ...webStyles.fontStyle_88, fontSize: '18px !important' }}>{this.formateDate(selectedCard?.attributes?.end_date)}</Typography>
                </Box>
                <Divider sx={webStyles.dividerStyle} />

                <Box style={styles.displayFlexBox}>
                    <Typography sx={webStyles.fontStyle_9}>{configJSON.comodity}</Typography>
                    <Typography sx={{ ...webStyles.fontStyle_88, fontSize: '18px !important' }}>{'Goods'}</Typography>
                </Box>
                <Divider sx={webStyles.dividerStyle} />

                <Box style={styles.displayFlexBox}>
                    <Typography sx={webStyles.fontStyle_9}>{configJSON.totalMiles}</Typography>
                    <Typography sx={{ ...webStyles.fontStyle_88, fontSize: '18px !important' }}>{this.formatLoadMiles(selectedCard?.attributes?.total_miles)}</Typography>
                </Box>
                <Divider sx={webStyles.dividerStyle} />

                <Box style={styles.displayFlexBox}>
                    <Typography sx={{ ...webStyles.fontStyle_9 }}>
                        {configJSON.originAddress.split(' ')[0]}
                        <br />
                        {configJSON.originAddress.split(' ')[1]}
                    </Typography>
                    <Typography sx={{ ...webStyles.fontStyle_88, fontSize: '18px !important' }}>
                    {selectedCard?.attributes?.origin_address?.address}, 
                        {selectedCard?.attributes?.origin_address?.city}, {selectedCard?.attributes?.origin_address?.state} <br />
                        {selectedCard?.attributes?.origin_address?.country} - {selectedCard?.attributes?.origin_address?.zip_code}
                    </Typography>
                </Box>
                <Divider sx={webStyles.dividerStyle} />

                <Box style={styles.displayFlexBox}>
                    <Typography sx={{ ...webStyles.fontStyle_9 }}>
                        {configJSON.destinationAddress.split(' ')[0]}
                        <br />
                        {configJSON.destinationAddress.split(' ')[1]}
                    </Typography>
                    <Typography sx={{ ...webStyles.fontStyle_88, fontSize: '18px !important' }}>
                    {selectedCard?.attributes?.destination_address?.address}, 
                        {selectedCard?.attributes?.destination_address?.city}, {selectedCard?.attributes?.destination_address?.state} <br />
                        {selectedCard?.attributes?.destination_address?.country} - {selectedCard?.attributes?.destination_address?.zip_code}
                    </Typography>
                </Box>
                <Divider sx={webStyles.dividerStyle} />

                <Box style={styles.displayFlexBox}>
                    <Typography sx={webStyles.fontStyle_9}>{configJSON.loadDetails}</Typography>
                    <Typography sx={{ ...webStyles.fontStyle_88, fontSize: '18px !important' }}>{selectedCard?.attributes?.load_details  || 'NA'}</Typography>
                </Box>
              </Box>

              {selectedCard?.attributes?.status === 'pending' && 
                <CancelAction className="cancel-action">
                  <button data-test-id="cancel-pending" onClick={()=> this.handleCancelPendingLoad(selectedCard.id)}>
                    CANCEL
                  </button>
                  <p>If you cancel your application now, you won’t be charged</p>
                </CancelAction>
              }
            </Box>
        {(this.state.tabValue === 1) &&
          <div style={{ padding: '16px' }}>
          <Card sx={{ display: 'flex', alignItems: 'center', marginBottom: '16px', height: '108px' }}>
            <Box sx={{ backgroundColor: '#B57730', borderRadius: '10px 0px 0px 10px', width: '22px', height: '108px' }} />
            <CardContent sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
              <img width={'71px'} height={'54px'} src={truckIcon} />
              <Typography variant="h6" sx={webStyles.statusHeading}>
              {selectedOption?.label}
              </Typography>
              <IconButton onClick={this.handleClickOpen } color="primary">
                <img src={button1} height={'66px'} width={'66px'} alt="clickImage" />
              </IconButton>
            </CardContent>
          </Card>
        </div>
        }
            
        </Box>
    )
}
  selectedCardStatus = () => {
    const selected: LoadResponse | undefined  = this.state.cards.find((card: LoadResponse) => card.id === this.state.selectedCardId);
    const check = selected?.attributes?.status || '';
    return check;
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
       <Box sx={webStyles.container}>
        <HeaderNavigation navigation={this.props.navigation} id={""} />
        <Box sx={webStyles.content}>
          <NavigationMenu navigation={this.props.navigation} id={""} />
          <Box sx={webStyles.mainContent}>
            <Box sx={webStyles.tabContainer}>
              <Typography sx={webStyles.headerFont}>{configJSON.labelTemplateName}</Typography>
              <Box sx={webStyles.root}>
                <Tabs
                  data-test-id="tabChange"
                  value={this.state.tabValue}
                  sx={webStyles.tabs}
                  onChange={this.handleChange}
                  TabIndicatorProps={{ style: { display: 'none' } }}
                >
                  <Tab label={configJSON.btnApplied} sx={webStyles.tab} />
                  <Tab label={configJSON.btnApprove} sx={webStyles.tab} />
                  <Tab label={configJSON.btnReject} sx={webStyles.tab} />
                </Tabs>
              </Box>

              {this.state.isLoading ? (
                              <Loader loading={this.state.isLoading} />
                            )
                          :
              <>     
              {this.state.cards.length > 0 &&
              <Box sx={webStyles.cardContainer}>
                <Box sx={{...webStyles.styledCardContainer}}>
                    {this.state.cards.map((cardItem, i) => {                      
                      const statusOption = this.statuses.find(option => option.value === cardItem.attributes.status);
                      return (

                        <Box
                          sx={{
                        ...webStyles.cardStyle,
                        border: this.getBorder(cardItem.id),
                        boxShadow: this.getBoxShadow(cardItem.id),
                      }}
                      data-test-id = "loadId"
                      key={cardItem.id + i}
                      onClick={() => {this.handleCardClick(cardItem.id)
                         this.setState({loaderId:cardItem.attributes.load_broker_id},()=>{
                         })  }}
                    >
                      <Box sx={webStyles.cardHeader}>
                        <Box sx={webStyles.avatarContainer}>
                          <Box sx={webStyles.nameContainer}>
                            <Typography
                              sx={{
                                ...webStyles.fontSyle_1
                              }}>
                              #ID {cardItem.id}
                            </Typography>              
                          </Box>
                        </Box>

                      </Box>
                    

                      <Box display={'flex'} flexDirection={'column'} sx={webStyles.boxPadding}>

                        <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                        <Box display='flex' gap='10px' alignItems='center' >
                          <Typography sx={{
                            ...webStyles.fontSyle_4,
                
                          }}
                          >{this.formatLoadWeight(cardItem?.attributes?.load_weight)} - {this.formatLoadMiles(cardItem?.attributes?.total_miles)}</Typography>
                        </Box>

                        <Box display='flex' justifyContent='flex-end' alignItems='center'>
                          <Typography sx={{
                            ...webStyles.fontSyle_3
                          }}
                          >
                           <PhoneIcon sx={{position:'relative',top:'5px'}} /> {cardItem?.attributes?.load_broker_phone_number}
                          </Typography>
                        </Box>
                        </Box>

                        <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                        <Stack direction="column" alignItems="flex-start"  >
                          <Stack direction="row" alignItems="center">
                            <Box
                              sx={{
                                width: 12,
                                height: 12,
                                backgroundColor: '#b0bec5',
                                borderRadius: '50%',
                                mt:'15px'
                              }}
                            />
                            <Typography sx={{ml:'8px',position:'relative',top:'10px'}}  variant="body1" color="textPrimary">
                              {cardItem?.attributes?.origin_address?.city}
                            </Typography>
                          </Stack>

                          <Box
                            sx={{
                              width: '1px',
                              height: '30px', 
                              backgroundColor: '#b0bec5', 
                              ml:'5px'
                            }}
                          />

                          <Stack direction="row" alignItems="center" >
                            <Box
                              sx={{
                                width: 12,
                                height: 12,
                                backgroundColor: '#0a1929', 
                                borderRadius: '50%',
                                mb:'15px'
                              }}
                            />
                            <Typography sx={{ml:'8px',position:'relative',bottom:'8px'}} variant="body1" color="textPrimary">
                              {cardItem?.attributes?.destination_address?.city}
                            </Typography>
                          </Stack>
                        </Stack>
                        <Box >
                          <Box sx={webStyles.chatBoxStyle} >
                            <Typography sx={{fontSize:'36px',fontWeight:700}}>$ {parseFloat(cardItem?.attributes?.price).toFixed(0)}</Typography>
                          </Box>
                        </Box>
                      </Box>
                      </Box>

                      <Box sx={webStyles.statusBox}>
                        <Typography sx={webStyles.fontSyle_5} data-test-id = "statusLabel">{statusOption?.label}</Typography>

                        <Box >
                          <Box onClick = {()=>this.chatOpen(cardItem?.attributes.load_broker_id)} data-test-id = "chatNow" sx={webStyles.chatBoxStyle1} >
                            <ChatBubbleOutlineOutlinedIcon />
                            <Typography style={{fontWeight:900,}}>Chat Now</Typography>
                          </Box>
                        </Box>
                      </Box>

                    </Box>
                  )}
                  )}


                </Box>
                <Box sx={{maxHeight: '730px', position: 'sticky', top: '20px', width: '40%', marginTop: '50px'}}>

                 {!this.state.chatS? this.renderTruckDetails():
                  this.chatScreen()}
                </Box>
                </Box>
                }

                {this.state.cards.length === 0 &&
                  <Box style={{textAlign: 'center',display: "flex", justifyContent: "center", alignItems:"center", height: '40vh',}}>
                    <Typography style={{...styles.noApplicants}}>
                        There is no load available with this status
                    </Typography>
                  </Box>}
                </>
                } 

              </Box>
              </Box>
              </Box>
              </Box>
              {this.state.openModal && 
                <Dialog
                  onClose={this.handleClose}
                  data-test-id="ModalForUpdateStatus" 
                  aria-labelledby="customized-dialog-title"
                  open={this.state.openModal}
                  PaperProps={{
                    sx: {
                      borderRadius: '8px 8px 32px 8px',
                    },
                  }}
                >
                  <DialogTitle sx={{ fontSize: '24px', fontWeight: 700, fontFamily: 'Inter', m: 0, p: 2 }} id="customized-dialog-title">
                    Update Status
                  </DialogTitle>
                  <IconButton
                    aria-label="close"
                    onClick={this.handleClose}
                    sx={{
                      position: 'absolute',
                      right: 10,
                      top: 15,
                      color: 'black',
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                  <DialogContent dividers sx={{ overflowX: "hidden" }}>
                    <Typography sx={{ fontSize: '20px', fontWeight: 700, color: '#64748B', width: '520px', paddingLeft: '20px', paddingRight: '30px', paddingTop: '20px' }}>
                      Status
                      <Box sx={{ marginTop: '18px', marginBottom: '18px', minWidth: 520, border: '1px solid #CBD5E1', borderRadius: '8px', height: '57px' }}>
                        <FormControl fullWidth>
                          <Select
                            onChange={this.handleStatusChange}
                            data-test-id="handleStatusChange"
                            value={this.state.selectedStatus || this.selectedCardStatus()}
                            inputProps={{
                              name: 'status',
                              id: 'mui-native-select',
                            }}
                            sx={{ height: '57px', fontSize: '20px', border: '0px solid white', color: '#475569', paddingLeft: '20px' }}
                          >
                            <MenuItem value="in_transit">In Transit</MenuItem>
                            <MenuItem value="weather_delay">Weather Delay</MenuItem>
                            <MenuItem value="road_closure">Road Closure</MenuItem>
                            <MenuItem value="break_down">Break Down</MenuItem>
                            <MenuItem value="delivered">Delivered</MenuItem>
                            <MenuItem value="pending">Pending</MenuItem>
                          </Select>
                        </FormControl>
                      </Box>
                    </Typography>
                  </DialogContent>
                  <DialogActions sx={{ height: '90px', marginRight: '15px' }}>
                    <Button variant="contained" sx={webStyles.cancelButton} data-testId={"delete_api"} onClick={this.handleClose}>Cancel</Button>
                    <Button variant="contained" sx={webStyles.LogoutButton} data-test-id="updateBtn" onClick={this.updateStatus}>Update</Button> 
                  </DialogActions>
                </Dialog>}
              {this.state.showToast && (

                
        <div style={{position: 'fixed',...styles.backdrop}} >
          <div 
            ref={this.state.toastRef} 
            style={{ position: 'relative',...styles.toast}}
          >
            <div style={styles.icon}>
              <span role="img" aria-label="check">
                <img src={tickIcon} alt="" />
              </span>
            </div>
            <div style={styles.message}>Status Updated Successfully</div>
            <button style={{ position: 'relative',...styles.closeButton}} onClick={this.closeToast}>
              <img src={crossIcon} alt="" />
            </button>
          </div>
        </div>
      )}

      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const CancelAction = styled('div')`
    text-align: center;
    button {
      border: 1px solid #D97706;
      color: #FFFBEB;
      padding: 16px 32px;
      border-radius: 8px;
      font-size: 16px;
      font-weight: 700;
      transition: opacity 0.2s;
      background: linear-gradient(99.09deg, #915F26 2.64%, #F19F40 100%);
        border: none;
      :disabled {
        background-color: #E2DACC;
        border-color: #E2DACC;
        opacity: 0.7;
        cursor: not-allowed;
      }
      :not(:disabled):hover {
        opacity: 0.9;
      }
    }
    p{
      color: #475569;
      font-family: Inter;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0%;
    }
`

const styles = {
  displayFlexBox:{display:'flex', justifyContent: 'space-between'},
  noApplicants: { fontSize:'30px', color: '#465567',fontFamily: "Inter",opacity: 0.5,},
  backdrop: {
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'end',
    zIndex: 999,
  },
  toast: {
    backgroundColor: '#fff',
    padding: '16px 24px',
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    marginBottom:'70px'
  },
  icon: {
    fontSize: '24px',
    marginRight: '16px',
  },
  message: {
    fontSize: '20px',
    fontWeight: 400,
    color: '#0F172A',
    fontFamily:'Inter'
  },
  closeButton: {
    background: 'none',
    border: 'none',
    color: '#999',
    cursor: 'pointer',
    left:'12px'
  },
  closeButtonHover: {
    color: '#333',
  },
}
const webStyles = {
  coverBox:{ padding: '30px',backgroundColor: '#FFF',display:'flex', gap:'40px' },
  imagePreview:{
    position:"absolute",
    left: "65%",
    bottom: "12%",
    width:"40%",
    maxWidth:'250px',
    zIndex:10,
  },
  clearButton: {
    float: "right",
    background: "red",
    borderRadius: "50%",
    height: "40px",
    width: "40px",
  },
  imageStyle:{
    width: '200px', 
    borderRadius: '8px', 
    zIndex: 10, 
    marginBottom:5
  },
  container: {
    display: "flex",
    flexDirection: "column",
    overflowX: 'hidden'
  },
  content: {
    display: "flex",
    flex: 1,
  },
  mainContent: {
    flex: 1,
    padding: "2rem",
    backgroundColor: '#E2E8F0',
  },
  tabContainer: {
    minHeight:'650px',
    backgroundColor: '#FFFFFF',
    border: '2px solid #215089',
    borderRadius: '16px',
    padding: '2rem',
  },
  infoBox: {
    width: '40%',
  },
  headerFont: {
    fontFamily: "Inter",
    fontSize: '30px',
    fontWeight: 700,
    color: "#215089"
  },
  root: {
    flexGrow: 1,
    backgroundColor: 'white',
    display: 'flex',
    gap: '6px',
    marginTop: '50px',
  },
  tabs: {
    borderRadius: '50px',
    backgroundColor: '#E2E8F0',
    color: '#64748B',
    padding: '6px 12px 6px 12px',
  },
  tab: {
    width: '140px',
    height:'30px',
    padding: '0px',
    borderRadius: '50px',
    fontFamily: "Inter",
    fontSize: '16px',
    fontWeight: 700,
    textTransform: 'initial',
    color: '#64748B',
    '&.Mui-selected': {
      backgroundColor: '#FBE1C4',
      color: '#B57730',
    },
  },
  
  cardContainer: {
    width: '100%',
    display: 'flex',
    position: 'relative',
    justifyContent: 'space-between'
  },
  styledCardContainer: {
    width: '55%',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '50px',
    gap: '30px',
    padding: '0 4px',
    overflowY:'auto',
      '::-webkit-scrollbar': {
        display: 'none',
      },
      scrollbarWidth: 'none', 
      '-msOverflowStyle': 'none', 
  },
  cardStyle: {    
    boxShadow: '0px 4px 4px 0px #00000040',
    border: '1px solid #FBE1C4',
    borderRadius: '16px',
    cursor: 'pointer',    
  },
  
  
  cardHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '20px 20px 10px 20px'
  },
  fontSyle_1: {
    fontFamily: "Inter",
    fontSize: '24px',
    fontWeight: 700,
  },
  fontSyle_2: {
    fontFamily: "Inter",
    fontSize: '14px',
    fontWeight: 400,
    color: "#FFFFFF"
  },
  avatarContainer: {
    display: 'flex',
    gap: '10px',
    alignItems: 'center'
  },
  nameContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
  },
  locationContainer: {
    display: 'flex',
    gap: '2px',
    alignItems: 'center'
  },
  avatarStyle: {
    height: '40px',
    width: '40px'
  },
  dividerStyle: {
    borderStyle: 'dotted',
    borderWidth: '1px',
    borderColor: '#BAC9DA',
  },
  boxPadding: {
    padding: '10px 0px 10px 20px',
    
  },
  fontSyle_3: {
    fontFamily: "Inter",
    fontSize: '16px',
    fontWeight: 400,
    color: "white",
    backgroundColor:'#215089',
    width:'199px',
    borderRadius:'3px 0px 0px 3px',
    textAlign:'center'
  },
  fontSyle_4: {
    fontFamily: "Inter",
    fontSize: '18px',
    fontWeight: 400,
    color: "#0F172A"
  },
  fontSyle_5: {
    fontFamily: "Inter",
    fontSize: '18px',
    fontWeight: 400,
    color: "#FFFFFF",
    textTransform:"capitalize"
  },
  statusBox: {
    backgroundColor: '#915F26',
    borderRadius: '0px 0px 14px 14px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding:'10px 10px 10px 20px'
  },
  chatBoxStyle: {
    height: '40px',
    padding: '28px 15px 6px 15px ',
    color:'#475569',
  },
  chatBoxStyle1: {
    width: '130px',
    height: '40px',
    backgroundColor: '#FBE1C4',
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '5px',
    color:'#B57730'
  },
  truckDetailsBox: {        
    borderRadius: '16px',    
    boxShadow: "0px 4px 4px 0px #00000040",
    height:'100%'
},
truckIconBox: {
    backgroundColor: '#E9EEF3',
    borderRadius: '16px',
    padding: '1rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem'
},
trcukPathBox: {
    display: 'flex',
    gap: '5rem',
    alignItems: 'center'
},
truckDetails: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
},
fontStyle_7: {
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: 400,
    color: "#0F172A"
},
fontStyle_8: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 700,
    color: "#1C1917",
    textAlign: 'Left'
},
fontStyle_88: {
  fontWeight: 700,
  fontSize: "16px",
  fontFamily: "Inter",
  textAlign: 'Left',
  color: "#1C1917",
  width: '350px',
  minWidth:'150px'
},
fontStyle_9: {
    minWidth:'130px',
    fontFamily: "Inter",
    fontSize: "18px",
    fontWeight: 400,
    color: "#475569"
},
  addresText: {
    fontSize: '18px', fontFamily: 'Inter', fontWeight: 700, marginTop: '10px', marginBottom: '10px'
  },
  cancelButton: {
    height:'56px',
    fontFamily: 'Inter',
    width:'120px',
    borderRadius:'8px',
    color: '#B57730',
    fontWeight: 700,
    fontSize: '16px',
    backgroundColor: '#FDF1E2',
    cursor: 'pointer',
    '&:hover': {
        backgroundColor: '#FDF1E2',
    }
},
LogoutButton: {
  height:'56px',
  width:'120px',
  borderRadius:'8px',
  fontSize: '16px',
  color: '#FFFFFF',
    fontFamily: 'Inter',
    cursor: 'pointer',
    fontWeight: 700,
    background: 'linear-gradient(99.09deg, #915F26 2.64%, #F19F40 100%)',
    '&:hover': {
        background: 'linear-gradient(99.09deg, #915F26 2.64%, #F19F40 100%)',
    }
},
statusHeading:{ 
  fontFamily: 'Inter', 
  fontSize: '24px', 
  fontWeight: 700, 
  color: '#1E293B', 
  margin: 'auto',
  textTransform:"capitalize"  
},
nameAvatar:{
  justifyContent:"center",
  display:"flex",
  alignItems:"center",
  background:"lightgray",
  borderRadius:"50%",
}
}
// Customizable Area End
