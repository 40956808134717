import React from 'react';

// Customizable Area Start
import { styled } from '@mui/material/styles';

import { mastercard, person } from './assets';
import PaymentHistoryController, { configJSON, Props } from './PaymentHistoryController';
import LayoutSettings from './LayoutSettings.web';
import { IconArrows } from './components/IconArrows';
import { IconDots } from './components/IconDots';
import { IconCancel } from './components/IconCancel';

// Customizable Area End

export default class PaymentHistory extends PaymentHistoryController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    const detailedItem = this.state.tableData[this.state.clickedItemIndex];
    return (
      // Customizable Area Start
      <LayoutSettings view="dashboard">
        <StyledSection data-test-id="payment-history">
          <h1>{configJSON.paymentHistoryTitle}</h1>
          <section className="container">
            <div className="no-items">
              <img src={person} alt={configJSON.personImageAlt} />
              <p>{configJSON.noItemsMessage}</p>
            </div>
            <div className="table-container">
              <table>
                <thead>
                  <tr>
                    <th>Amount</th>
                    <th>Payment Date</th>
                    <th>Status</th>
                    <th>Load ID</th>
                    <th>Origin - Destination</th>
                    <th>
                      <button>
                        <IconDots />
                      </button>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.tableData.map(
                    ({ amount, loadId, origin, destination, paymentDate, status }, index) => (
                      <tr key={index}>
                        <td>{amount}</td>
                        <td>{paymentDate}</td>
                        <td>
                          <span className={`status-${status}`}>{status}</span>
                        </td>
                        <td>{loadId}</td>
                        <td>{`${origin.city} - ${destination.city}`}</td>
                        <td onClick={() => this.handleOpenDetails(index)}>
                          <button>
                            <IconDots />
                          </button>
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>
          </section>

          <div className="payment-modal" data-open-details={this.state.isDetailsOpen}>
            <div className="modal-bg" onClick={this.handleCloseDetails} />
            <section className="card">
              <div className="content">
                <button className="close" onClick={this.handleCloseDetails}>
                  <IconCancel />
                </button>
                <div className="header">
                  <span>{detailedItem.origin.city}</span>
                  <IconArrows /> <span>{detailedItem.destination.city}</span>
                </div>
                <div className="payment-info">
                  <div className="amount">
                    <span>Amount</span>
                    <strong>{detailedItem.amount}</strong>
                  </div>
                  <div className="payment">
                    <span>
                      Paid: <span> {detailedItem.paymentDate}</span>
                    </span>
                    <div className="credit-card">
                      <img src={mastercard} alt="credit card branch" />
                      <span>{detailedItem.paymentMethod}</span>
                      <span>{detailedItem.cardNumber}</span>
                    </div>
                  </div>
                </div>
                <div className="load-info">
                  <div className="weight">
                    <span>Load weight</span>
                    <strong>{detailedItem.loadWeight}</strong>
                  </div>
                  <div className="truck">
                    <span>Truck type</span>
                    <strong>{detailedItem.truckType}</strong>
                  </div>
                </div>
                <div className="details-wrapper">
                  <table className="details">
                    <tr>
                      <th>Start Date</th>
                      <td>{detailedItem.startDate}</td>
                    </tr>
                    <tr>
                      <th>End Date</th>
                      <td>{detailedItem.endDate}</td>
                    </tr>
                    <tr>
                      <th>Commodity</th>
                      <td>{detailedItem.commodity}</td>
                    </tr>
                    <tr>
                      <th>Total Distance</th>
                      <td>{detailedItem.totalDistance}</td>
                    </tr>
                    <tr>
                      <th>Origin Address</th>
                      <td>
                        {detailedItem.origin.name}, {detailedItem.origin.address}
                      </td>
                    </tr>
                    <tr>
                      <th>Destination Adress</th>
                      <td>
                        {detailedItem.destination.name}, {detailedItem.destination.address}
                      </td>
                    </tr>
                    <tr>
                      <th>Load Details</th>
                      <td>
                        <td>{detailedItem.loadDetails}</td>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </section>
          </div>
        </StyledSection>
      </LayoutSettings>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const StyledSection = styled('section')`
  font-family: 'Inter';

  h1 {
    color: #215089;
    font-size: 30px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: -0.005em;
  }
  .container {
    background-color: white;
    padding: 24px 30px;
    border-radius: 8px;
    .no-items {
      display: grid;
      place-content: center;
      display: none;
    }

    .table-container {
      border-radius: 8px;
      border: 1px solid #e2e8f0;
      overflow: hidden;
    }
    table {
      width: 100%;
      border-collapse: collapse;
      tr {
        border-collapse: collapse;
        display: grid;
        align-items: center;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
        border-bottom: 1px solid #e2e8f0;
      }
      th {
        padding: 10px 20px;
        text-align: left;
        color: #64748b;
        font-size: 16px;
        font-weight: 400;
      }
      td {
        padding: 26px 20px;
        text-align: left;
        font-size: 16px;
        color: #334155;
        font-weight: 400;
      }

      td span {
        font-weight: 400;
        font-size: 12px;
        border-radius: 16px;
        padding: 8px;

        &.status-Completed {
          background-color: #ecfdf5;
          color: #10b981;
        }
        &.status-Processing {
          background-color: #fffbeb;
          color: #f59e0b;
        }
        &.status-Error {
          background-color: #fef2f2;
          color: #ef4444;
        }
      }
      button {
        padding: 0;
        border: none;
        background-color: transparent;
        color: #94a3b8;
      }
      th:has(button),
      td:has(button) {
        justify-self: flex-end;
      }
    }
  }
  @media (width < 786px) {
    .container table {
      tr {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 0.5fr;
      }
      th {
        padding: 8px 12px;
        font-size: 14px;
      }
      td {
        padding: 20px 12px;
        font-size: 14px;
        span {
          padding: 4px;
          font-size: 10px;
        }
      }
    }
  }
  @media (width < 560px) {
    .container {
      padding: 8px;
      table {
        tr {
          grid-template-columns: 1fr 1fr 1fr 0.5fr;
          grid-template-areas: 'amount date id button' 'origin origin status button';

          > *:nth-of-type(1) {
            grid-area: amount;
          }
          > *:nth-of-type(2) {
            grid-area: date;
          }
          > *:nth-of-type(3) {
            grid-area: status;
          }
          > *:nth-of-type(4) {
            grid-area: id;
          }
          > *:nth-of-type(5) {
            grid-area: origin;
          }
          > *:nth-of-type(6) {
            grid-area: button;
          }

          td {
            padding: 12px 14px;
          }
        }
      }
    }
  }

  .payment-modal {
    position: relative;
    button {
      padding: 0;
      border: none;
      background-color: transparent;
    }
    .modal-bg {
      position: fixed;
      width: 100%;
      height: 100%;
      inset: 0;
      background-color: rgba(0, 0, 0, 0.05);
      display: none;
    }
    .card {
      position: fixed;
      top: 0;
      height: 100%;
      width: 480px;
      transform: translateX(105%);
      right: 0;
      background-color: white;
      box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 20px;
      transition: all 0.2s ease-out;

      height: 100vh;
      @media (width < 786px) {
        width: 100%;
      }
    }

    .content {
      display: grid;
      gap: 32px;
      padding: 32px 40px;
      color: #475569;

      height: 100%;

      .close {
        justify-self: flex-end;
      }
      .header {
        display: flex;
        gap: 8px;
        span {
          color: #2563eb;
          font-size: 24px;
          font-weight: 700;
          line-height: 32px;
          letter-spacing: -0.005em;
        }
      }
      .payment-info {
        display: grid;
        grid-template-columns: 0.8fr 1fr;
        gap: 8px;
        line-height: 25px;

        .amount {
          display: grid;
          strong {
            color: #1e293b;
            font-size: 24px;
            font-weight: 700;
            line-height: 32px;
          }
        }
        .payment {
          display: grid;
          .credit-card {
            display: flex;
            gap: 8px;
            align-items: center;
            span:first-of-type {
              color: #0f172a;
            }
            &:last-child {
              font-variant-numeric: tabular-nums;
            }
          }
        }
      }

      .load-info {
        display: grid;
        grid-template-columns: 0.8fr 1fr;
        line-height: 25px;
        .weight,
        .truck {
          display: grid;
          strong {
            color: #1e293b;
            line-height: 32px;
            font-weight: 400;
            font-size: 24px;
          }
        }
      }

      .details-wrapper {
        overflow-y: auto;
      }
      .details {
        tr {
          display: grid;
          grid-template-columns: 0.5fr 1fr;
          gap: 8px;
          border-top: 1px dashed #cbd5e1;
          padding: 16px 0;
          th {
            color: #475569;
            font-weight: 400;
            text-align: left;
          }

          td {
            color: #1e293b;
            font-weight: 700;
          }
        }
      }
    }
  }
  .payment-modal[data-open-details='true'] {
    .modal-bg {
      display: block;
    }
    .card {
      transform: translateX(0%);
    }
  }
`;
// Customizable Area End
