import React from 'react';

import { mastercard, visa } from './../assets';
import { styled } from '@mui/material/styles';
import { IconDots } from './IconDots';
import { IconDelete } from './IconDelete';
import { IconCheck } from './IconCheck';
import { IconCancel } from './IconCancel';

interface PaymentMethodItemProps {
  brand: string;
  expMont: number;
  expYear: number;
  lastFour: string;
  isPrimary: boolean;
}

interface BrandDetails {
  [key: string]: { name: string; logo: string };
}
const brandDetails: BrandDetails = {
  visa: { name: 'Visa', logo: visa },
  mastercard: { name: 'Mastercard', logo: mastercard },
  amex: { name: 'American Express', logo: visa },
  discover: { name: 'Discover', logo: visa },
  diners: { name: 'Diners Club', logo: visa },
  unionpay: { name: 'UnionPay', logo: visa },
  jcb: { name: 'JCB', logo: visa },
  unknown: { name: 'Card', logo: visa },
};

interface Props {
  id: string;
  brand: string;
  expMont: number;
  expYear: number;
  lastFour: string;
  isPrimary: boolean;

  onSetPrimary: (id: string) => void;
  onDeleteCard: (id: string) => void;
}

interface S {
  isActionsOpen: boolean;
  isDeleteModalOpen: boolean;
}
export default class PaymentMethodItem extends React.Component<Props, S> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isActionsOpen: false,
      isDeleteModalOpen: false,
    };
  }

  handleSetPrimary = () => {
    this.props.onSetPrimary(this.props.id);
    this.setState({ isActionsOpen: false });
  };
  handleDeleteCard = () => {
    this.props.onDeleteCard(this.props.id);
    this.setState({ isDeleteModalOpen: false });
  };

  render() {
    const { brand, expMont, expYear, lastFour, isPrimary, id } = this.props;
    const { isActionsOpen, isDeleteModalOpen } = this.state;
    return (
      <StyledLi data-selected={isPrimary}>
        <div className="type">
          <img src={brandDetails[brand].logo} alt={`${brand}_logo`} />
          <span>{brandDetails[brand].name}</span>
        </div>
        <div className="exp-date">
          Expiry date:{' '}
          <span>{`${expMont.toString().padStart(2, '0')}/${expYear.toString().slice(-2)}`}</span>
        </div>
        <div className="number">
          Card number: <span>**** {lastFour}</span>
        </div>
        <div className="actions">
          {isPrimary && <span className="primary">Primary</span>}
          <button onClick={() => this.setState({ isActionsOpen: true })} data-test-id="actions">
            <IconDots />
          </button>

          {isActionsOpen && (
            <section className="actions-modal">
              <div className="background" onClick={() => this.setState({ isActionsOpen: false })} />
              <div className="card">
                {!isPrimary && (
                  <button
                    className="action primary"
                    onClick={this.handleSetPrimary}
                    data-test-id="set-primary"
                  >
                    <IconCheck /> <span>Set as primary</span>
                  </button>
                )}
                <button
                  className="action delete"
                  data-test-id="delete"
                  onClick={() => this.setState({ isDeleteModalOpen: true, isActionsOpen: false })}
                >
                  <IconDelete /> <span> Delete</span>
                </button>
              </div>
            </section>
          )}
          {isDeleteModalOpen && (
            <div className="delete-modal">
              <div
                className="background"
                onClick={() => this.setState({ isDeleteModalOpen: false })}
              />
              <div className="content">
                <button
                  onClick={() => this.setState({ isDeleteModalOpen: false })}
                  className="close"
                >
                  <IconCancel />
                </button>
                <p>Are you sure you want to delete your payment card?</p>
                <div className="buttons">
                  <button
                    className="cancel"
                    onClick={() => this.setState({ isDeleteModalOpen: false })}
                  >
                    CANCEL
                  </button>
                  <button onClick={this.handleDeleteCard} data-test-id="delete-confirm">
                    DELETE
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </StyledLi>
    );
  }
}

const StyledLi = styled('li')`
  list-style: none;
  border-radius: 8px;
  padding: 26px 20px;
  border: 1px solid #d6d3d1;
  border-left: 4px solid #d6d3d1;

  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr 96px;
  grid-template-areas: 'type exp numb actions';
  gap: 8px;

  /* reset */
  button {
    border: none;
    background-color: transparent;
    padding: 0;
    font-family: inherit;
  }

  &[data-selected='true'] {
    border-left: 4px solid #059669;
  }

  .type {
    display: flex;
    gap: 8px;
    font-weight: 700;
    color: #0f172a;
    grid-area: type;
  }
  .exp-date,
  .number {
    color: #1e293b;
    span {
      font-variant-numeric: tabular-nums;
      color: #64748b;
    }
  }
  .exp-date {
    grid-area: exp;
  }
  .number {
    grid-area: numb;
  }

  .actions {
    grid-area: actions;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;

    button {
      color: #64748b;
    }
    .primary {
      color: #059669;
      font-weight: 700;
      margin-right: 8px;
    }
  }
  .actions-modal {
    .background {
      position: fixed;
      width: 100%;
      height: 100%;
      inset: 0;
      z-index: 10;
    }

    .card {
      z-index: 11;
      position: absolute;
      top: 100%;
      right: 0;
      padding: 16px;
      border-radius: 8px;
      background-color: white;
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
      width: 180px;
      display: grid;
      gap: 8px;

      .action {
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 8px;
        font-family: Inter;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0%;

        &.delete {
          color: #dc2626;
        }
      }
    }
  }
  .delete-modal {
    .background {
      background-color: rgba(0, 0, 0, 0.03);
      position: fixed;
      width: 100%;
      height: 100%;
      inset: 0;
      z-index: 10;
    }
    .content {
      position: fixed;
      width: fit-content;
      max-width: 600px;
      height: fit-content;
      inset: 0;
      z-index: 13;
      margin: auto;

      padding: 32px;
      border-radius: 8px;
      background-color: white;
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
      display: grid;
      gap: 8px;
      .close {
        justify-self: end;
      }
      p {
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: -0.5%;
      }
      .buttons {
        display: flex;
        justify-content: flex-end;
        gap: 16px;
        font-weight: 700;
        font-size: 16px;

        button {
          border: 1px solid #d97706;
          background-color: #f59e0b;
          color: #fffbeb;
          padding: 10px 16px;
          border-radius: 8px;
          font-size: 16px;
          font-weight: 700;

          &.cancel {
            border: 1px solid #fdf1e2;
            background-color: #fdf1e2;
            color: #b57730;
          }
        }
      }
    }
  }

  @media (width < 768px) {
    grid-template-columns: 1fr 1fr 1fr 96px;
    grid-template-areas: 'type type type actions' 'exp exp exp exp' 'numb numb numb .';
  }
`;
