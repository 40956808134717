export const imgPasswordVisible = require('../assets/ic_password_visible.png');
export const imgPasswordInVisible = require('../assets/ic_password_invisible.png');
export const imgVisbility = require('../assets/visibility.svg');
export const imgVisbilityOff = require('../assets/visibilityOff.svg');
export const tickIcon = require('../assets/Icon.png');
export const crossIcon = require('../assets/image_.png');
export const logo = require('../assets/logo2.png');
export const mastercard = require('../assets/mastercardLogo.svg');
export const visa = require('../assets/visaLogo.svg');

export const person = require('../assets/person.svg');
