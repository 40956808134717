import { IBlock } from '../../../framework/src/IBlock';
import { Message } from '../../../framework/src/Message';
import { BlockComponent } from '../../../framework/src/BlockComponent';
import MessageEnum, { getName } from '../../../framework/src/Messages/MessageEnum';
import { runEngine } from '../../../framework/src/RunEngine';

// Customizable Area Start

// Customizable Area End

export const configJSON = require('./config');

export interface TableData {
  amount: string;
  paymentDate: string;
  status: string;
  loadId: string;
  paymentMethod: string;
  cardNumber: string;
  loadWeight: string;
  truckType: string;
  startDate: string;
  endDate: string;
  commodity: string;
  totalDistance: string;
  origin: Location;
  destination: Location;
  loadDetails: string;
}
export interface Location {
  city: string;
  name: string;
  address: string;
}

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  tableData: TableData[];
  clickedItemIndex: number;
  isDetailsOpen: boolean;
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class PaymentHistoryController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End
    this.state = {
      tableData: JSON.parse(decodeURI(configJSON.encodedMockedTableData)),
      clickedItemIndex: 0,
      isDetailsOpen: false,
    };
    // Customizable Area Start
    // Customizable Area End
  }

  handleOpenDetails = (i: number) => {
    this.setState({ clickedItemIndex: i, isDetailsOpen: true });
  };
  handleCloseDetails = () => {
    this.setState({ isDetailsOpen: false });
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // async componentDidMount() {
  //   super.componentDidMount();
  // }

  // Customizable Area End
}
