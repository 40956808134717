import React from 'react';
import { styled } from '@mui/material/styles';

import ApplyModalController, { Props } from './ApplyModalController';
import { CardItem } from './CardItem.web';
import CustomStripeForm from '../../../settings2/src/CustomStripeForm.web';
import { CardIcon } from './CardIcon';

export default class ApplyModal extends ApplyModalController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <ModalSection data-test-id="applymodal-method">
        <div className="background" onClick={this.props.onClickClose}></div>
        <div className="modal">
          <div className="cards">
            {this.state.isLoadingCards && <p>Loading cards...</p>}
            {!this.state.isLoadingCards &&
              this.state.cardsList.length === 0 &&
              this.state.strapiApiKey && (
                <div className="no-items">
                  <div className="message">
                    <CardIcon />
                    <p>
                      No existing payment method! Please set up a payment method and confirm your
                      application. Your card will be charged with $40 only if your application is
                      accepted.
                    </p>
                  </div>
                  <hr />
                  <CustomStripeForm
                    oldDesign
                    stripeApiKey={this.state.strapiApiKey}
                    onSaveCard={this.saveCard}
                  />
                </div>
              )}

            {!this.state.isLoadingCards && this.state.cardsList.length > 0 && (
              <div className="with-items">
                <div className="message">
                  <CardIcon />
                  <p>
                    Are you sure you want to apply? Your account will be charged a $40 fee, if your
                    application is accepted.
                  </p>
                </div>
                <ul className="methods-list">
                  {this.state.cardsList.map(({ card, payment_method_id: id }) => (
                    <CardItem
                      amount={40}
                      key={id}
                      id={id}
                      brand={card.brand}
                      expMont={card.exp_month}
                      expYear={card.exp_year}
                      lastFour={card.last4}
                      onCardClick={this.handleCardClicked}
                      selected={this.state.selectedCardId === id}
                    />
                  ))}
                </ul>

                <div className="buttons" data-old={this.props.oldDesign}>
                  <button
                    disabled={this.state.selectedCardId === ''}
                    onClick={this.props.onClickApply}
                  >
                    Apply
                  </button>
                  <button onClick={this.props.onClickClose} className="cancel">
                    Cancel
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </ModalSection>
    );
  }
}

const ModalSection = styled('section')`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  font-family: 'Inter';

  * {
    box-sizing: border-box;
  }

  .background {
    z-index: 101;
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    background-color: #0000001d;
  }

  .modal {
    z-index: 102;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 8px;
    padding: 32px 64px;
    width: min(630px, 90%);

    .no-items {
      display: grid;
      .message {
        display: grid;
        grid-template-columns: auto 1fr;
        align-items: center;
        gap: 32px;
        svg {
          margin-inline: 16px;
          width: 32px;
          height: 32px;
        }
        p {
          font-weight: 700;
        }
      }
      hr {
        margin-block: 32px;
        width: 100%;
      }
      img {
        align-self: center;
        margin-inline: auto;
        height: 120px;
        margin-bottom: 16px;
      }
    }

    .with-items {
      display: grid;
      gap: 32px;
      .message {
        display: grid;
        grid-template-columns: auto 1fr;
        align-items: center;
        gap: 32px;
        svg {
          margin-inline: 16px;
          width: 32px;
          height: 32px;
        }
        p {
          font-weight: 700;
        }
      }
      .methods-list {
        padding: 0 0 0 0;
        margin: 0;
        display: grid;
        gap: 8px;
        max-height: 160px;

        @media (width < 768px) {
          max-height: 204px;
        }

        /* Scrollbar width */
        &::-webkit-scrollbar {
          width: 8px; /* Adjust as needed */
          display: none;
        }
        /* Track (background) */
        &::-webkit-scrollbar-track {
          background-color: #d9d9d9;
          background-color: transparent;
          border-radius: 5px;
        }
        /* Thumb (scroll handle) */
        &::-webkit-scrollbar-thumb {
          background-color: #888;
          border-radius: 5px;
        }
        /* Thumb on hover */
        &::-webkit-scrollbar-thumb:hover {
          background-color: #6f6f6f;
        }

        /* Show scrollbar only when there are +3 children*/
        &:has(li:nth-child(n + 3)) {
          overflow-y: scroll;
          padding-right: 4px;
          &::-webkit-scrollbar {
            display: block;
          }
        }
      }

      .buttons {
        display: grid;
        gap: 16px;
        button {
          border: 1px solid #d97706;
          background: #f59e0b;
          color: #fffbeb;
          padding: 16px;
          border-radius: 8px;
          font-size: 16px;
          font-weight: 700;
          transition: opacity 0.2s;

          :disabled {
            background: #e2dacc;
            border-color: #e2dacc;
            opacity: 0.7;
            cursor: not-allowed;
          }

          :not(:disabled):hover {
            opacity: 0.9;
          }
        }

        &[data-old='true'] {
          button {
            background: linear-gradient(99.09deg, #915f26 2.64%, #f19f40 100%);
            border: none;
            :disabled {
              background: #f1f1f1;
              color: #6d6d6d;
            }
            &.cancel {
              background: #fdf1e2;
              color: #b57730;
            }
          }
        }
      }
    }
  }
`;
