import React from "react";

// Customizable Area Start
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import HeaderNavigation from "../../navigationmenu/src/HeaderNavigation.web";
import {Box} from "@mui/material"
// Customizable Area End

import HyperlinksController, {
  Props,
} from "./HyperlinksController";

export default class Hyperlinks extends HyperlinksController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <Box sx={webStyles.container}>
          <HeaderNavigation navigation={this.props.navigation} id={""} />
          <Box sx={webStyles.content}>
            <NavigationMenu navigation={this.props.navigation} id={""} />
            <Box sx={webStyles.mainContent}>
              <Box sx={webStyles.cardContainer}>
                <div style={webStyles.headerFont}>Hyperlinks</div>
                <Box sx={{marginTop:'2rem'}}>
                  {
                    this.state.linkData?.map((links, index)=>{
                      return <Box style={{
                                  lineHeight: 2,
                                  fontSize: 20,
                                  fontFamily: 'Inter',
                                  cursor: "pointer",
                                  color: '#B57730',
                                  textDecoration: 'underline',
                                  width:'max-content'
                                }}
                                key={index}
                                data-testId="linkId"
                                onClick={() => window.open(links.attributes.link)}
                                >
                                  {this.getSpliceName((index+1.+" "+links.attributes.link), 90)}
                              </Box>
                    })
                  }
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const webStyles = {
  container: {
    display: "flex",
    flexDirection: "column",
    overflowX: 'hidden'
  },
  content: {
    display: "flex",
    flex: 1,
  },
  mainContent: {
    flex: 1,
    padding: "2rem",
    backgroundColor: '#E2E8F0',
  },
  cardContainer: {
    backgroundColor: '#FFFFFF',
    border: '2px solid #215089',
    borderRadius: '16px',
    padding: '2rem',
    height:'774px'
  },
  headerFont: {
    fontFamily: "Inter",
    fontSize: '30px',
    fontWeight: 700,
    color: "#215089"
  },
}
// Customizable Area End
