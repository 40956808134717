import React from 'react';
export const IconArrows: React.FC = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.7375 12.5011V14.7386C18.7375 15.3011 18.0625 15.5761 17.675 15.1886L14.2 11.7011C13.9625 11.4511 13.9625 11.0636 14.2 10.8136L17.675 7.32614C18.0625 6.92614 18.7375 7.20114 18.7375 7.76364V10.0011H26.25C26.9375 10.0011 27.5 10.5636 27.5 11.2511C27.5 11.9386 26.9375 12.5011 26.25 12.5011H18.7375ZM3.75 17.5011H11.2625V15.2511C11.2625 14.7011 11.9375 14.4136 12.325 14.8136L15.8 18.3011C16.0375 18.5511 16.0375 18.9386 15.8 19.1886L12.325 22.6761C11.9375 23.0761 11.2625 22.8011 11.2625 22.2386V20.0011H3.75C3.0625 20.0011 2.5 19.4386 2.5 18.7511C2.5 18.0636 3.0625 17.5011 3.75 17.5011Z"
        fill="#D97706"
      />
    </svg>
  );
};
