import React from 'react';

// Customizable Area Start

import { styled } from '@mui/material/styles';
import LayoutSettings from './LayoutSettings.web';
import PaymentController, { Props, configJSON } from './PaymentController';
import CustomStripeForm from './CustomStripeForm.web';
import PaymentMethodItem from './components/PaymentMethodItem';
import { person } from './assets';

// Customizable Area End

export default class Payment extends PaymentController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <LayoutSettings view="dashboard">
        <StyledDiv data-test-id="payment-method">
          <h1 className="title">Payment Method</h1>
          <section className="payments">
            {this.state.isLoadingCards && <p>Loading cards...</p>}
            {this.state.isErrorCardsList && <p>Error loading cards. Please refresh the site</p>}
            {!this.state.isLoadingCards &&
              !this.state.isErrorCardsList &&
              this.state.cardsList.length === 0 && (
                <div className="no-items">
                  <img src={person} alt={configJSON.personImageAlt} />
                  <p>{configJSON.noCardsMessage}</p>
                </div>
              )}
            {!this.state.isLoadingCards && this.state.cardsList.length > 0 && (
              <ul className="methods-list">
                {this.state.cardsList.map(({ payment_method_id, default: isPrimary, card }) => (
                  <PaymentMethodItem
                    key={payment_method_id}
                    id={payment_method_id}
                    brand={card.brand}
                    expMont={card.exp_month}
                    expYear={card.exp_year}
                    lastFour={card.last4}
                    isPrimary={isPrimary}
                    onDeleteCard={this.handleDeleteCard}
                    onSetPrimary={this.handleSetPrimary}
                  />
                ))}
              </ul>
            )}

            <button data-test-id="new-card" className="add-new" onClick={this.handleOpenCardForm}>
              <span>➕ </span>ADD NEW CARD
            </button>

            {this.state.isFormOpen && this.state.strapiApiKey && (
              <section className="stripe-wrapper" data-test-id="payment-stripe-form">
                <hr />
                <h2>Enter card details</h2>
                <CustomStripeForm
                  stripeApiKey={this.state.strapiApiKey}
                  onSaveCard={this.handleSaveCard}
                />
                <button className="cancel" onClick={this.handleCloseCardForm}>
                  CANCEL
                </button>
              </section>
            )}
          </section>
        </StyledDiv>
      </LayoutSettings>

      // Customizable Area End
    );
  }
}

// Customizable Area Start
const StyledDiv = styled('div')`
  .title {
    color: #215089;
    font-size: 30px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: -0.005em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }

  .payments {
    padding: 24px 30px;
    background-color: white;
    border-radius: 8px;

    display: grid;
    gap: 32px;

    .methods-list {
      margin: 0;
      padding: 0;
      display: grid;
      gap: 24px;
    }

    .no-items {
      display: grid;
      place-content: center;
      p {
        text-align: center;
      }
    }

    .add-new {
      border: 1px solid #d97706;
      background-color: #f59e0b;
      color: #fffbeb;
      padding: 10px 16px;
      border-radius: 8px;
      font-size: 16px;
      font-weight: 700;
      justify-self: end;
      display: flex;
      gap: 8px;
    }
  }

  .stripe-wrapper {
    margin-top: 16px;
    display: grid;
    gap: 16px;
    hr {
      width: 100%;
      border: 1px solid #e2e8f0;
    }

    .cancel {
      max-width: 500px;
      border: 1px solid #d97706;
      background-color: #f59e0b;
      color: #fffbeb;
      padding: 16px;
      border-radius: 8px;
      font-size: 16px;
      font-weight: 700;
    }
  }
`;
// Customizable Area End
