import React from 'react';

import { mastercard, visa } from './../assets';
import { styled } from '@mui/material/styles';

interface CardItemProps {
  id: string;
  brand: string;
  expMont: number;
  expYear: number;
  lastFour: string;
  amount?: number;
  onCardClick?: (id: string) => void;
  selected?: boolean;
}

interface BrandDetails {
  [key: string]: { name: string; logo: string };
}
const brandDetails: BrandDetails = {
  visa: { name: 'Visa', logo: visa },
  mastercard: { name: 'Mastercard', logo: mastercard },
  unknown: { name: 'Card', logo: visa },
};

export const CardItem: React.FC<CardItemProps> = ({
  id,
  brand,
  expMont,
  expYear,
  lastFour,
  amount,
  onCardClick,
  selected = false,
}) => {
  return (
    <StyledCard onClick={() => onCardClick?.(id)} data-selected={selected}>
      <div className="type">
        <img src={brandDetails[brand].logo} alt={`${brand}_logo`} />
        <span>{brandDetails[brand].name}</span>
      </div>
      <div className="exp-date">
        Expiry date:{' '}
        <span>{`${expMont.toString().padStart(2, '0')}/${expYear.toString().slice(-2)}`}</span>
      </div>
      <div className="number">
        Card number: <span>**** {lastFour}</span>
      </div>
      <div className="actions">
        {selected && <span className="amount">${amount}</span>}
        <input type="checkbox" id="agree" name="agree" checked={selected} />
      </div>
    </StyledCard>
  );
};

const StyledCard = styled('li')`
  list-style: none;
  border-radius: 8px;

  border: 1px solid #d6d3d1;
  border-left: 4px solid #d6d3d1;

  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;

  padding: 8px 14px;
  display: grid;
  grid-template-columns: 1.1fr 1fr 1fr 72px;
  grid-template-areas: 'type exp exp actions' '. numb numb actions';
  gap: 8px;
  row-gap: 0;

  .type {
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 700;
    color: #0f172a;
    grid-area: type;
    img {
      width: 40px;
    }
  }
  .exp-date,
  .number {
    color: #1e293b;
    span {
      font-variant-numeric: tabular-nums;
      color: #64748b;
    }
  }
  .exp-date {
    grid-area: exp;
  }
  .number {
    grid-area: numb;
  }

  .actions {
    grid-area: actions;
    font-family: inherit;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 4px;
    color: #059669;
    .amount {
      font-weight: 700;
      font-size: 24px;
    }
    input {
      min-width: 10px;
      min-height: 10px;
      border: 2px solid #cfcfcf;
      border-radius: 50%; /* Makes it rounded */
      appearance: none; /* Removes default styling */
      background-color: white;

      :checked {
        background-color: #059669;
        border-color: #059669;
      }
    }
  }

  &[data-selected='true'] {
    border: 1px solid #059669;
    border-left: 4px solid #059669;
  }

  @media (width < 768px) {
    grid-template-columns: 1fr 1fr 1fr 64px;
    grid-template-areas: 'type type type actions' 'exp exp exp exp' 'numb numb numb .';
  }
`;
