import React from 'react';

export const IconDelete: React.FC = () => {
  return (
    <svg width="18" height="18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m11.092 2.467.533.533H13.5c.412 0 .75.337.75.75 0 .412-.338.75-.75.75h-9a.752.752 0 0 1-.75-.75c0-.413.338-.75.75-.75h1.875l.532-.533a.757.757 0 0 1 .526-.217h3.135c.194 0 .39.083.524.217ZM4.5 14.25c0 .825.675 1.5 1.5 1.5h6c.825 0 1.5-.675 1.5-1.5v-7.5c0-.825-.675-1.5-1.5-1.5H6c-.825 0-1.5.675-1.5 1.5v7.5Zm2.25-7.5h4.5c.412 0 .75.338.75.75v6c0 .412-.338.75-.75.75h-4.5A.752.752 0 0 1 6 13.5v-6c0-.412.338-.75.75-.75Z"
        fill="currentColor"
      />
    </svg>
  );
};
