import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
import { SelectChangeEvent } from '@mui/material/Select'
import { City } from "./LoadBrokerSearchController.web";
import moment from "moment";
import React from "react";

export interface AllLoads {
    id: number;
    attributes: LoadsAttributes
}

export interface LoadsAttributes {
    id: number;
    load_weight: string;
    total_miles: string;
    origin_address: {
        city: string;
        state: string;
        street: string;
        country: string;
        zip_code: string;
        address: string
    },
    destination_address: {
        city: string;
        state: string;
        street: string;
        country: string;
        zip_code: string;
        address: string;
    },
    start_date: string;
    end_date: string;
    price: string;
    load_broker_credit_score: string;
    load_broker_phone_number: string;
    created_at: string;
    updated_at: string;
    commodity?:string;
    details?:string
    applied?:boolean
    truck_type: string;
    load_details: string;
    full_name: string;
    email: string;
    company_name: string;
}
interface ProfileAttributes {
    id: number;
    first_name: string;
    last_name: string;
    phone_number: string | null;
    email: string;
    truck_type: string[];
    insurance: string | null;
    mc_number: string | null;
    driving_license_number: string | null;
    authority: string | null;
    user_profile_image: string | null;
  }
  interface ProfileData {
    id: string;
    type: string;
    attributes: ProfileAttributes;
  }
  interface ProfileMeta {
    message: string;
  }
  interface ProfileResponse {
    data: ProfileData;
    meta: ProfileMeta;
  }
export interface Pagination {
    current_page: number,
    next_page: number,
    prev_page: number,
    total_count: number,
    total_pages: number
}

// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
    navigation: any;
    // Customizable Area Start
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    searchText: string;
    selectedValue: string;
    selectedOrigin: City | null;
    selectedDestination: City | null;
    startDate: Date | null;
    endDate: Date | null;
    allLoadsdata: AllLoads[];
    currentPage: number;
    itemsPerPage: number;
    loadDetails: LoadsAttributes | null;
    selectedLoadId: number;
    distanceFrom: string;
    distanceTo: string;
    loadWeightFrom: string;
    loadWeightTo: string;
    payRangeFrom: string;
    payRangeTo: string;
    creditScoreFrom: string;
    creditScoreTo: string;
    cityOptions:City[],
    originInputValue:string,
    destinationInputValue:string,
    originValue:City|null,
    destinationValue:City|null,
    totalPage:number,
    totalCount:number,
    loading:boolean,
    trucker:boolean
    showToast:boolean;
    toastRef:any,
    applied:boolean | undefined

    isModalOpen: boolean

    // Customizable Area End
}
interface SS { }

export default class LoadBoardListViewController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    showLoadDetailsAPICallId: string = "";
    getSearchedCitiesAPICallId: string = "";
    getSearchedByFilterAPICallId: string = "";
    userAccountId:string = ""
    applyLoadsId:string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage)
        ];

        this.state = {
            searchText: '',
            selectedValue: 'van',
            selectedOrigin: {} as City,
            selectedDestination: {} as City,
            startDate: null,
            endDate: null,
            allLoadsdata: [],
            currentPage: 1,
            itemsPerPage: 4,
            loadDetails: null,
            selectedLoadId: 0,
            distanceFrom: "",
            distanceTo: "",
            loadWeightFrom: "",
            loadWeightTo: "",
            payRangeFrom: "",
            payRangeTo: "",
            creditScoreFrom: "",
            creditScoreTo: "",
            cityOptions: [],
            originInputValue:"",
            destinationInputValue:"",
            originValue: null,
            destinationValue: null,
            totalPage: 0,
            totalCount: 0,
            loading:true,
            trucker:false,
            showToast:false,
            toastRef : React.createRef(),
            applied:false,

            isModalOpen: false
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        // Customizable Area Start                
        this.initialLoaded();
        this.UserData()
    document.addEventListener('mousedown', this.handleClickOutside);
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const webApiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let webResponseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            switch (webApiRequestCallId) {
                case this.getSearchedByFilterAPICallId:
                    this.handleAllLoadsAPIResponse(webResponseJson);
                    break;

                case this.showLoadDetailsAPICallId:
                    this.handleLoadDetailsAPIResponse(webResponseJson);
                    break;

                case this.getSearchedCitiesAPICallId: 
                   this.setState({ cityOptions: webResponseJson.data });   
                   
                   const selectedCity = await getStorageData('searched_cityName');
                   
                   if(selectedCity) {
                    const foundCity = webResponseJson.data.find((city: City) => city.attributes.city_ascii == selectedCity)                   
                    
                    if(foundCity) {
                        
                        let searchByCity = {
                            distanceFrom: foundCity,
                            distanceTo: this.state.selectedDestination,
                            startDate: this.state.startDate,
                            endDate: this.state.endDate,
                            truckType: this.state.selectedValue
                        }                        
                        await setStorageData('searchByCity', JSON.stringify(searchByCity));
                        await removeStorageData('searched_cityName');
                        this.setState({selectedOrigin: foundCity,searchText:"", currentPage: 1 },                             
                            async()=> {                                
                                
                                this.handleUpdatedSearch(selectedCity)
                                this.searchByFilter(foundCity.attributes.city_ascii, true)
                                

                            }
                        )                        
                    }
                   }                   
                   break;
                case this.applyLoadsId:
                    this.handleApplyLoad(webResponseJson)
                    break
                case this.userAccountId:
                    this.handleGetData(webResponseJson)
            }
        }
        // Customizable Area End
    }
    // Customizable Area Start
    getFullName = (full_name: string | null) => {
        if(full_name !== null) return full_name
        return 'NA'
    }
    getEmail = (email: string) => {
        if(email) return email
        return 'NA'
    }
    getCoName = (coName: string | null) => {
        if(coName !== null) return coName
        return 'NA'
    }
    getLoadDetails = (details: string) => {
        if(details) return details
        return 'NA'
    }
    handleUpdatedSearch = (value: string) => {
        this.setState(
          prevState => ({
            searchText: value,
            selectedOrigin: prevState.selectedOrigin && {
              ...prevState.selectedOrigin,
              attributes: {
                ...prevState.selectedOrigin.attributes,
                city_ascii: value,
                state_name: ""
              }
            },
            selectedDestination:null,
            loadWeightFrom :"",
            loadWeightTo:"",
            distanceFrom:"",
            distanceTo:"",
            selectedValue: "",
            payRangeFrom :"",
            payRangeTo:"",
            creditScoreFrom :"", 
            creditScoreTo:"",
            startDate :null,
            endDate: null,
          }),
                () => this.handleSearchChange()
              );
      }
    handleSearchChange = async () => {
        const selectedCity = await getStorageData('searched_cityName');
        if (selectedCity) {            
          this.searchByFilter(selectedCity, true);
        }
      };
    getCounts = (counts : number)=> {
        if(counts === 1){
            return `${counts} Load`
        } else return `${counts} Loads`
    }
    initialLoaded = async () => {
        const selectedCity = await getStorageData('searched_cityName');
        if(selectedCity) {            
            this.setState({ originInputValue: selectedCity.slice(0,4) as string},
            () => {
                if (this.state.originInputValue.length <= 5) {                        
                        this.getAllSearchedCities(true, this.state.originInputValue )
                    }}
                );                
        }     
    }

    initialLoad = async () => {
        const selectedCity = await getStorageData('searched_cityName');
        this.searchByFilter(selectedCity, true);
    }
    dateFormate = (date: string, format:string) => {
       return moment(date).format(format);
    }
    
    handleCardClick = (loadIdNumber : number) => {
        this.setState({ selectedLoadId : loadIdNumber },
            () => {
                this.showLoadDetails(this.state.selectedLoadId);
            }
        );        
    };

    handleChange = (event : SelectChangeEvent) => {
        this.setState({ selectedValue: event.target?.value });
    };

    handleStartDateChange = (date: Date | null) => {
        this.setState({ startDate: date });
    }

    handleEndDateChange = (date: Date | null) => {
        this.setState({ endDate: date });
    };

    handlePageChange = async(event: React.ChangeEvent<unknown>, page: number) => {
        const selectedCity = await getStorageData('searched_cityName');
       
        this.setState({ currentPage: page }, () => {
            if (selectedCity) {
                this.searchByFilter(selectedCity, true);
            } else 
            this.searchByFilter()
         }
        );
    }

    showLoadDetails = async (index: number) => {
        const header = {
            "Content-Type": configJSON.advancedsearchApiContentType,
            token: await getStorageData("LoginToken")
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.showLoadDetailsAPICallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.allLoadsAPIEndPoint}/${index}`);
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpGetMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    handleAllLoadsAPIResponse = (webResponseJson:{loads: { data: AllLoads[] },meta:Pagination}) => {
        if (webResponseJson) {

            const loadsData = webResponseJson.loads.data;
            const metaData = webResponseJson.meta;
            const firstLoadId = loadsData.length > 0 ? loadsData[0].id : 0;
            
            this.setState({
                allLoadsdata: loadsData,
                selectedLoadId: this.state.selectedLoadId !== 0 ? this.state.selectedLoadId : firstLoadId,
                currentPage:metaData.current_page,
                totalPage: metaData.total_pages,
                totalCount:metaData.total_count,
                originValue: this.state.selectedOrigin,
                destinationValue: this.state.selectedDestination,
                loading:false,
            }, () => {
                if(this.state.selectedLoadId !== 0){
                    const loadApplyStatus = 
                        loadsData.find(
                            load =>
                                load.id === this.state.selectedLoadId
                        );
                    this.setState({
                        applied:loadApplyStatus?.attributes.applied
                    })
                    this.showLoadDetails(this.state.selectedLoadId);
                }else if(firstLoadId){
                    this.setState({applied:loadsData[0].attributes.applied})
                    this.showLoadDetails(firstLoadId);
                }
            });
        }

    }

    handleLoadDetailsAPIResponse = (webResponseJson: { data: LoadsAttributes }) => {
            
        if (webResponseJson) {
            this.setState({ loadDetails: webResponseJson.data, loading:false });
        }
    }

    isValidInput = (value: string): boolean => {
        return  /^\d*$/.test(value);
    };

    isValidNumber = (value: string): boolean => {
        return /^(\d+(\.\d*)?|\.\d+)?$/.test(value);
    };
    handleChangeDistanceFrom = (event: React.ChangeEvent<HTMLInputElement>)=> {
        this.isValidNumber(event.target.value) && this.setState({distanceFrom:event.target.value});
    }

    handleChangeDistanceTo = (event: React.ChangeEvent<HTMLInputElement>)=> {
        this.isValidNumber(event.target.value) && this.setState({distanceTo:event.target.value});
    }

    handleChangeLoadWeightFrom = (event: React.ChangeEvent<HTMLInputElement>)=> {
        this.isValidNumber(event.target.value) && this.setState({loadWeightFrom:event.target.value});
    }

    handleChangeLoadWeightTo = (event: React.ChangeEvent<HTMLInputElement>)=> {
        this.isValidNumber(event.target.value) && this.setState({loadWeightTo:event.target.value});
    }

    handleChangePayRangeFrom = (event: React.ChangeEvent<HTMLInputElement>)=> {
        this.isValidNumber(event.target.value) && this.setState({payRangeFrom:event.target.value});
    }

    handleChangePayRangeTo = (event: React.ChangeEvent<HTMLInputElement>)=> {
        this.isValidNumber(event.target.value) && this.setState({payRangeTo:event.target.value});
    }
    
    handleInputChange = (event: React.ChangeEvent<{}>, value: string) => {
       this.setState({ originInputValue: value },
        () => {
            if (this.state.originInputValue.length <= 5) {
                this.getAllSearchedCities(true)
            }}
        );
    };

    handleDestinationInputChange = (event: React.ChangeEvent<{}>, value: string) => {
        this.setState({ destinationInputValue: value },
            () => {
                if (this.state.destinationInputValue.length <= 5) {
                    this.getAllSearchedCities(false)
                }}
        );
    };

    handleCityChange = (event: React.ChangeEvent<{}>, newValue: City | null) => {
        if (newValue) {
            this.setState({ selectedOrigin: newValue });
        } else {
            this.setState({ selectedOrigin: null });
        }
    };

    handleDestinationCityChange = (event: React.ChangeEvent<{}>, newValue: City | null) => {
        if (newValue) {
            this.setState({ selectedDestination: newValue });
        } else {
            this.setState({ selectedDestination: null });
        }
    };

    getAllSearchedCities = async(origin:boolean, city?: string) =>{
        const header = {
            "Content-Type": configJSON.advancedsearchApiContentType,
            token: await getStorageData("LoginToken")
        };
        const endpoint  = origin 
            ? `${configJSON.searchCityAPIEndPoint}${this.state.originInputValue != 'undefined' ? this.state.originInputValue : city}`
            : `${configJSON.searchCityAPIEndPoint}${this.state.destinationInputValue}`

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getSearchedCitiesAPICallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endpoint);
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpGetMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage); 
    }

    getRoutes = (endpoint:string) => {
        if(this.state.startDate && this.state.endDate){
            endpoint =`${endpoint}&filters[start_date][]=${this.state.startDate}&filters[end_date][]=${this.state.endDate}`
        }

        if(this.state.payRangeFrom && this.state.payRangeTo){
            endpoint =`${endpoint}&filters[pay_range][]=${this.state.payRangeFrom}&filters[pay_range][]=${this.state.payRangeTo}`
        }

        if(this.state.creditScoreFrom && this.state.creditScoreTo){
            endpoint =`${endpoint}&filters[lbcs][]=${this.state.creditScoreFrom}&filters[lbcs][]=${this.state.creditScoreTo}`
        }

        if(this.state.selectedOrigin && this.state.selectedDestination){
            endpoint =`${endpoint}&filters[origin_name][]=${this.state.selectedOrigin.attributes.city_ascii}&filters[destination_name][]=${this.state.selectedDestination.attributes.city_ascii}`
        }
        return endpoint;
    }
    searchByFilter = async(selectedCity? : string, isSearch?: boolean) => {
        
        const header = {
            "Content-Type": configJSON.advancedsearchApiContentType,
            token: await getStorageData("LoginToken")
        };

        let endpoint = `${configJSON.searchByFilterAPIEndPoint}?page=${this.state.currentPage}&per_page=4`
        if(isSearch){
            endpoint =`${endpoint}&filters[origin_name][]=${selectedCity}`
        } else {               
        if(this.state.selectedOrigin?.attributes.city_ascii) {
            endpoint =`${endpoint}&filters[origin_name][]=${this.state.selectedOrigin.attributes.city_ascii}`
        }
            
        if(this.state.loadWeightFrom && this.state.loadWeightTo){
            endpoint =`${endpoint}&filters[load_weight][]=${this.state.loadWeightFrom}&filters[load_weight][]=${this.state.loadWeightTo}`
        }

        if(this.state.distanceFrom && this.state.distanceTo){
            endpoint =`${endpoint}&filters[total_miles][]=${this.state.distanceFrom}&filters[total_miles][]=${this.state.distanceTo}`
        }

        endpoint = this.getRoutes(endpoint)

        if(this.state.selectedValue){
            endpoint =`${endpoint}&filters[truck_types]=${this.state.selectedValue}`
        }
        }
        
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getSearchedByFilterAPICallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endpoint);
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpGetMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage); 
    }


    formatLoadWeight = (loadWeight: string, fieldText? : boolean , prefix?: boolean ) => {
        const numericWeight = parseFloat(loadWeight);
        if (isNaN(numericWeight)) return '';

        const weight = numericWeight % 1 === 0
            ? numericWeight.toFixed(0)
            : numericWeight.toFixed(1);

        if (prefix === true) {
            return weight
        } else if (fieldText === true) {
            return `${numericWeight === 1 ? 'Ton' : 'Tons'}`
        } else {
            return `${weight} ${numericWeight === 1 ? 'Ton' : 'Tons'}`;
        } 
    }
    handleChangeCreaditScoreFrom = (event: React.ChangeEvent<HTMLInputElement>)=> {
        this.isValidInput(event.target.value) && this.setState({creditScoreFrom:event.target.value});
    }
    
    formatLoadMiles = (miles: string, fieldText? : boolean , prefix?: boolean) => {
        const numericMiles = parseFloat(miles);
        if (isNaN(numericMiles)) return '';

        const weight = numericMiles % 1 === 0
            ? numericMiles.toFixed(0)
            : numericMiles.toFixed(1);

        if (prefix === true) {
            return weight
        } else if (fieldText === true) {
            return `${numericMiles === 1 ? 'Mile' : 'Miles'}`
        } else {
            return `${weight} ${numericMiles === 1 ? 'Mile' : 'Miles'}`;
        } 
    }

    formatePrice = (price: string) => {
        const numericPrice = parseFloat(price);
        if (isNaN(numericPrice)) return '';
        const newPrice = numericPrice % 1 === 0
            ? numericPrice.toFixed(0)
            : numericPrice.toFixed(1);
        return newPrice;
    }
    handleChangeCreaditScoreTo = (event: React.ChangeEvent<HTMLInputElement>)=> {
        this.isValidInput(event.target.value) && this.setState({creditScoreTo:event.target.value});
    }
    applyLoads = (validId:number)=>{
        const header = {
        "Content-Type": configJSON.advancedsearchApiContentType,
          token: localStorage.getItem('LoginToken')
        };
        const httpBody = {
            "load_id": validId
          };
      
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    
        this.applyLoadsId = requestMessage.messageId;
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          'bx_block_load/applications'
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), 'POST');
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(httpBody)
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
      }
    
    
    handleApplyLoad = async(response:any) => {
        if(response.message){
            this.setState({ isModalOpen: false, showToast: true });
            const selectedCity = await getStorageData('searched_cityName');
            if(selectedCity){
                this.initialLoad()
            } else {
                this.initialLoaded()
            }
        }
    }

    handleClickOutside = () => {
        this.cloasToast()
    };
    cloasToast =  ()=>{
        this.setState({showToast:false})
    }
    UserData = () => {
        const header = {
          token: localStorage.getItem('LoginToken')
        };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.userAccountId = requestMessage.messageId;
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          'bx_block_profile/profiles/fetch_user_personal_details'
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.httpGetMethod
        );
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
      };
    
      handleGetData= (response:ProfileResponse)=> {
        const {data} = response;
        const {attributes}= data;
        if(attributes.insurance){
                this.setState({trucker:true})
        }
      }

    handleOpenApplyModal = () => {
        this.setState({isModalOpen: true})
    };
    handleCloseApplyModal = () => {
        this.setState({isModalOpen: false})
    };

    // Customizable Area End
}