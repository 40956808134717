import React from 'react';

export const IconCancel: React.FC = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.4 0.614252C17.1509 0.364581 16.8127 0.224271 16.46 0.224271C16.1073 0.224271 15.7691 0.364581 15.52 0.614252L8.99996 7.12092L2.47996 0.600918C2.23085 0.351248 1.89265 0.210938 1.53996 0.210938C1.18727 0.210938 0.84907 0.351248 0.599961 0.600918C0.0799609 1.12092 0.0799609 1.96092 0.599961 2.48092L7.11996 9.00092L0.599961 15.5209C0.0799609 16.0409 0.0799609 16.8809 0.599961 17.4009C1.11996 17.9209 1.95996 17.9209 2.47996 17.4009L8.99996 10.8809L15.52 17.4009C16.04 17.9209 16.88 17.9209 17.4 17.4009C17.92 16.8809 17.92 16.0409 17.4 15.5209L10.88 9.00092L17.4 2.48092C17.9066 1.97425 17.9066 1.12092 17.4 0.614252Z"
        fill="#334155"
      />
    </svg>
  );
};
